// Dependencies
import React, { FunctionComponent } from 'react';

// Types
import { AuthFormProps } from './auth-form.types';

// Styles
import {
  Container,
  ErrorMessage,
  InputElementsWrapper,
} from './auth-form.styles';
import { ConditionallyRender } from 'components/utilities/conditionally-render';

// Components

export const AuthForm: FunctionComponent<AuthFormProps> = ({
  errorMessage,
  inputElements,
  actionElement,
  additionalElement,
}) => {
  return (
    <Container>
      <ConditionallyRender
        shouldRender={!!errorMessage}
        content={<ErrorMessage>{errorMessage}</ErrorMessage>}
      />

      <InputElementsWrapper>{inputElements}</InputElementsWrapper>

      <ConditionallyRender
        shouldRender={!!additionalElement}
        content={additionalElement}
      />

      {actionElement}
    </Container>
  );
};
