// Dependencies
import styled from 'styled-components'
import { pageGutter } from 'styles/gutter'
import { MediaQueries } from 'utils/breakpoints'

export const Container = styled.div`
  ${pageGutter}

  padding: 0;

  ${MediaQueries.medium} {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`
export const Content = styled.div`
  ${MediaQueries.medium} {
    width: 100%;
    padding: 0;
    gap: 40px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
`

export const VideoPlayerAndResultsWrapper = styled.div`
  ${MediaQueries.medium} {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
  }

  ${MediaQueries.amedium} {
    display: grid;
    grid-template-columns: 5fr 3fr;
    gap: 24px;
  }

  ${MediaQueries.hd} {
    grid-template-columns: 4fr 2fr;
  }
`

export const ResultsSectionWrapper = styled.div`
  ${MediaQueries.medium} {
    /* ${pageGutter} */

    padding: 0;
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }

  ${MediaQueries.amedium} {
    gap: 14px;
    flex-direction: column;

    max-width: 100% !important;
    padding: 0 !important;
  }
`

export const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  align-items: center;
  padding: 24px;

  ${MediaQueries.medium} {
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
    padding: 0;
  }
`

export const SectionTitle = styled.h1`
  color: #25272d;
  font-family: 'Source Sans 3';
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  line-height: 40px;

  ${MediaQueries.amedium} {
    font-size: 40px;
    line-height: 48px;
  }
`
