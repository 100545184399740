// Dependencies
import React, { Fragment, HTMLAttributes } from 'react'
import loadable from '@loadable/component'

// Components
const Footer = loadable(() => import('components/Footer'))
const WhatsDoubts = loadable(() => import('../whatssapp'))
const SEO = loadable(() => import('components/seo'))
const NavBar = loadable(() => import('components/Navbar'))

// Styles
import * as S from './styles'

// Utils
import { currentYear } from 'utils/constants'

interface IProps extends HTMLAttributes<HTMLElement> {
  children?: React.ReactNode
  mode?: string
  theme?: 'christmas' | 'default'
  disableFixed?: boolean
  screen?: 'home' | 'gabaritos'
}

const Layout = ({
  children,
  mode = 'proenem',
  theme = 'default',
  screen = 'home',
  ...rest
}: IProps) => {
  return (
    <Fragment>
      <S.Wrapper {...rest}>
        <SEO
          title={`Gabarito ENEM ${currentYear} extraoficial`}
          description="Gabarito ENEM 2024 extraoficial, simule e descubra sua nota, com precisão de 94% da TRI e conquiste sua aprovação na faculdade dos seus sonhos."
        />
        <NavBar />
        {children}
        <WhatsDoubts />
        <Footer />
      </S.Wrapper>
    </Fragment>
  )
}

export default Layout
