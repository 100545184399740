// Dependencies
import React, { FunctionComponent } from 'react'
import { Skeleton } from 'components/utilities/skeleton'

// Styles
import { DetailsWrapper, ResultsCardItemContainer } from './results-card.styles'

export const ResultsCardItemSkeleton: FunctionComponent = () => {
  return (
    <ResultsCardItemContainer>
      <DetailsWrapper>
        <Skeleton animationColor='#f1f1f7' height={24} borderRadius={4} width={72} />

        <Skeleton animationColor='#f1f1f7' height={24} borderRadius={4} width={82} />
      </DetailsWrapper>

      <Skeleton animationColor='#f1f1f7' height={10} borderRadius={320} />

      {/* <Skeleton animationColor='#f1f1f7' height={20} borderRadius={4} width={136} /> */}
    </ResultsCardItemContainer>
  )
}
