type SaveBrowserStorage = {
  key: string
  value: string
  useLocal?: boolean
}

type FetchBrowserStorage = {
  key: string
  useLocal?: boolean
}

type CleanBrowserStorage = {
  key: string
}

export const saveBrowserStorageData = ({
  key,
  value,
  useLocal = true
}: SaveBrowserStorage) => {
  if (typeof window !== 'undefined') {
    if (useLocal) {
      return localStorage.setItem(key, value)
    }

    return sessionStorage.setItem(key, value)
  }
}

export const fetchBrowserStorageData = ({
  key,
  useLocal = true
}: FetchBrowserStorage) => {
  if (typeof window !== 'undefined') {
    if (useLocal) {
      return localStorage.getItem(key)
    }

    return sessionStorage.getItem(key)
  }

  return null
}

export const cleanBrowserStorageData = ({ key }: CleanBrowserStorage) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key)
    sessionStorage.removeItem(key)
  }
}
