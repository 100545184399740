// Dependencies
import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect
} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

// Components
import arrowLeft from 'assets/svg/home/arrow-left.svg'

// Utils
import { SignInFormData, signInFormValidationSchema } from './sign-in.schema'
import { AuthContent } from 'component-library/auth-content'
import { Input } from 'component-library/input'
import { AuthForm } from 'component-library/auth-form'
import { Breadcrumb } from 'component-library/breadcrumb'
import { Button } from 'component-library/button'
import { icons } from 'component-library/icon/icon.data'
import { Link } from 'component-library/link'

type SignInPageProps = {
  errorMessage?: string
  handleBreadcrumbClick: () => void
  handleSignInSubmit: (formData: SignInFormData) => void
}

export const SignInPage: FunctionComponent<SignInPageProps> = ({
  errorMessage,
  handleSignInSubmit,
  handleBreadcrumbClick
}) => {
  const { handleSubmit, formState, control, watch } = useForm<SignInFormData>({
    resolver: zodResolver(signInFormValidationSchema),
    defaultValues: {
      signIn: {
        email: '',
        password: ''
      }
    }
  })

  const formGeneralInformations = watch('signIn')

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit(handleSignInSubmit)()
    }
  }

  return (
    <AuthContent
      handleSubmit={handleSubmit(handleSignInSubmit)}
      breadcrumbElement={
        <Breadcrumb
          label="Voltar"
          backIcon={arrowLeft}
          handleClick={handleBreadcrumbClick}
        />
      }
      title="Já sou aluno"
      description="Bem-vindo. Acesse a ferramenta com os mesmos dados que utiliza para acessar a plataforma."
      formComposition={
        <AuthForm
          inputElements={
            <Fragment>
              <Controller
                name="signIn.email"
                control={control}
                render={({ onChange }) => (
                  <Input
                    id="email"
                    placeholder="Seu e-mail"
                    value={formGeneralInformations.email ?? ''}
                    onChange={(event) => onChange(event.target.value)}
                    onKeyDown={handleKeyDown}
                    disabled={formState.isSubmitting}
                    {...(formState.errors?.signIn?.email && {
                      variant: 'error',
                      errorMessage: formState.errors?.signIn?.email.message
                    })}
                  />
                )}
              />

              <Controller
                name="signIn.password"
                control={control}
                render={({ onChange }) => (
                  <Input
                    id="password"
                    placeholder="Sua senha"
                    type="password"
                    hasInputTypeToggle
                    disabled={formState.isSubmitting}
                    onKeyDown={handleKeyDown}
                    value={formGeneralInformations.password ?? ''}
                    onChange={(event) => onChange(event.target.value)}
                    additionalElement={
                      <Link
                        label="Esqueci a senha"
                        variant="password"
                        href="https://app.proenem.com.br/recuperar-senha"
                      />
                    }
                    {...(formState.errors?.signIn?.password && {
                      variant: 'error',
                      errorMessage: formState.errors?.signIn?.password.message
                    })}
                  />
                )}
              />
            </Fragment>
          }
          actionElement={
            <Button
              isFullyAdaptative
              variant="primary"
              label="Entrar"
              type="submit"
              id="sendForm"
              borderType="small"
              isLoading={formState.isSubmitting}
            />
          }
          {...(!!errorMessage && { errorMessage })}
        />
      }
      footerElement={
        <Link
          label="Termos de uso"
          variant="term"
          href="https://www.proenem.com.br/termos-de-uso"
        />
      }
    />
  )
}
