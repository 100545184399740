import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

type ContentStyleProps = {
  isActive: boolean
}

export const Content = styled.div<ContentStyleProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  iframe {
    margin: 0 auto;
    display: block;
    width: 100%;
    height: 204px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      display: flex;
      position: fixed;
      z-index: 3;
      background-color: #fff;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    `}

  @media (min-width: 768px) {
    iframe {
      height: 470px;
      border-radius: 16px;
    }

    ${({ isActive }) =>
      isActive &&
      css`
        top: auto;
        bottom: 24px;
        left: 24px;
        max-width: 426px;
        height: 240px;
      `}
  }

  @media (min-width: 1024px) {
    iframe {
      height: 100%;
    }
  }
`

export const CloseWrapper = styled.div`
  display: flex;
  padding: 4px 12px 4px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: #ced0dd;
  position: absolute;
  top: -38px;
  right: 4px;
  cursor: pointer;
`

export const CloseLabel = styled.p`
  color: #25272d;
  font-family: 'Source Sans 3';
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`
