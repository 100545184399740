// Dependencies
import { ConditionallyRender } from 'components/utilities/conditionally-render'
import React, { FunctionComponent } from 'react'

// Styles
import {
  Container,
  DetailsWrapper,
  Label,
  OpenedContent,
  ResultsCardItemContainer,
  ResultsCompositionWrapper,
  ResultsLabel,
  Separator,
  SimulateSisuDescription,
  SimulateSisuTitle,
  SimulateSisuTitleWrapper,
  SimulateSisuWrapper,
  StaticContent,
  SubLabel,
  TotalHitsWrapper
} from './results-card.styles'

// Types
import { ResultsCardProps, ResultsCardItemProps } from './results-card.types'

export const ResultsCard: FunctionComponent<ResultsCardProps> = ({
  isExpanded,
  resultsLabel,
  expandActionElement,
  resultsComposition,
  simulateSisuActionElement,
  simulateSisuDescription,
  simulateSisuTitle,
  totalHits
}) => {
  return (
    <Container>
      <StaticContent>
        <ResultsLabel>{resultsLabel}</ResultsLabel>

        <TotalHitsWrapper>
          <Label>{totalHits}</Label>

          <ConditionallyRender
            maximumBreakpoint="tablet"
            content={expandActionElement}
          />
        </TotalHitsWrapper>
      </StaticContent>

      <ConditionallyRender
        shouldRender={!!isExpanded}
        content={
          <OpenedContent>
            <ResultsCompositionWrapper>
              {resultsComposition}
            </ResultsCompositionWrapper>

            <Separator />

            <SimulateSisuWrapper>
              <SimulateSisuTitleWrapper>
                <ConditionallyRender
                  minimumBreakpoint="tablet"
                  content={
                    <SimulateSisuTitle>{simulateSisuTitle}</SimulateSisuTitle>
                  }
                />

                <SimulateSisuDescription>
                  {simulateSisuDescription}
                </SimulateSisuDescription>
              </SimulateSisuTitleWrapper>

              {simulateSisuActionElement}
            </SimulateSisuWrapper>
          </OpenedContent>
        }
      />
    </Container>
  )
}

export const ResultsCardItem: FunctionComponent<ResultsCardItemProps> = ({
  name,
  hits,
  estimatedTriScore,
  shouldDisplayTriScore,
  progressBarElement
}) => {
  return (
    <ResultsCardItemContainer>
      <DetailsWrapper>
        <Label>{name}</Label>

        <Label>{hits}</Label>
      </DetailsWrapper>

      {progressBarElement}

      <ConditionallyRender
        shouldRender={!!estimatedTriScore && shouldDisplayTriScore}
        content={<SubLabel>{estimatedTriScore}</SubLabel>}
      />
    </ResultsCardItemContainer>
  )
}
