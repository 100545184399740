// Dependencies
import React, { FunctionComponent } from 'react'

// Types
import { AnswerExamSectionProps } from './answer-exam-section.types'

// Styles
import {
  Container,
  Content,
  ExamActionsCompositionWrapper,
  ExamOptionsSelectorsCompositionWrapper,
  ExamQuestionsCompositionWrapper,
  ExamYearSelectorCompositionWrapper,
  Header,
  WarningDescription,
  WarningTitle,
  WarningWrapper
} from './answer-exam-section.styles'

export const AnswerExamSection: FunctionComponent<AnswerExamSectionProps> = ({
  warningTitle,
  warningDescription,
  examActionsComposition,
  examOptionsSelectorsComposition,
  examQuestionsComposition,
  examYearSelectorComposition
}) => {
  return (
    <Container>
      <Header>
        <WarningWrapper>
          <WarningTitle>{warningTitle}</WarningTitle>

          <WarningDescription>{warningDescription}</WarningDescription>
        </WarningWrapper>

        <ExamOptionsSelectorsCompositionWrapper>
          {examOptionsSelectorsComposition}
        </ExamOptionsSelectorsCompositionWrapper>
      </Header>

      <Content>
        <ExamActionsCompositionWrapper>
          {examActionsComposition}
        </ExamActionsCompositionWrapper>

        <ExamQuestionsCompositionWrapper>
          {examQuestionsComposition}
        </ExamQuestionsCompositionWrapper>

        <ExamYearSelectorCompositionWrapper>
          {examYearSelectorComposition}
        </ExamYearSelectorCompositionWrapper>
      </Content>
    </Container>
  )
}
