// Dependencies
import styled from 'styled-components';

export const Container = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const Label = styled.p`
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #ED002B;
`;

export const Icon = styled.img`
  display: flex;
  flex-shrink: 0;
`;
