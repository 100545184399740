// Dependencies
import Reaptcha from 'reaptcha';
import React, { useState, useCallback, useEffect, useRef } from 'react';

export const useRecaptcha = () => {
  const recaptchaRef = useRef<Reaptcha>(null);

  const [recaptchaToken, setRecaptchaToken] = useState<string | undefined>(
    undefined,
  );
  const [recaptchaReady, setRecaptchaReady] = useState(false);

  const handleVerify = useCallback(
    (token: string) => setRecaptchaToken(token),
    [setRecaptchaToken],
  );

  const handleRender = useCallback(() => {
    if (recaptchaRef && recaptchaRef.current) {
      setRecaptchaReady(true);
      recaptchaRef.current.execute();
    }
  }, []);

  useEffect(() => {
    if (recaptchaRef.current) {
      const interval = setInterval(() => {
        setRecaptchaToken(undefined);
      }, 90000);

      return () => interval && clearInterval(interval);
    }

    return () => false;
  }, [setRecaptchaToken]);

  useEffect(() => {
    if (!recaptchaToken && recaptchaRef.current && recaptchaReady) {
      recaptchaRef.current.reset();

      recaptchaRef.current.execute();
    }
  }, [recaptchaReady, recaptchaToken]);

  return {
    recaptchaRef,
    recaptchaToken,
    handleVerify,
    handleRender,
  };
};
