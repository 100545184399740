// Dependencies
import styled, { css } from 'styled-components';

type ContainerStyleProps = {
  hasError?: boolean;
};

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 12px;
  border: 1px solid #ced0dd;
  border-radius: 8px;
  background-color: #ffffff;
  transition: all 0.3s;

  &:hover {
    border-color: #4d4d63;
  }
`;

export const UnderInputMessage = styled.p`
  font-family: 'Source Sans 3', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const Container = styled.div<ContainerStyleProps>`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;

  ${({ hasError }) =>
    hasError &&
    css`
      ${InputWrapper} {
        border-color: #b41f21;
      }

      ${UnderInputMessage} {
        color: #b41f21;
      }
    `}
`;

export const Label = styled.p`
  font-family: 'Source Sans 3', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #3a3e47;
`;

type InputFieldStyleProps = {
  isEmailInput?: boolean;
};

export const InputField = styled.input<InputFieldStyleProps>`
  width: 100%;
  background-color: transparent;

  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  ${({ isEmailInput }) =>
    !!isEmailInput &&
    css`
      text-transform: lowercase;

      ::placeholder {
        text-transform: none;
      }
    `}
`;

export const PasswordIcon = styled.img`
  display: block;
  flex-shrink: 0;
  cursor: pointer;
`;
