import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  > div.bar-container {
    width: 100%;
    height: 10px;
    background: #fdf4f5;
    border: 1px solid #fdf4f5;
    border-radius: 50px;
    overflow: hidden;
  }
`

type ProgressStyleProps = {
  progress: number
}

export const Progress = styled.div<ProgressStyleProps>`
  width: ${({ progress }) => `${progress}%`};
  height: 10px;
  transition: all 0.3s;
  background: #50c79d;
`
