// Dependencies
import React, { Fragment, FunctionComponent } from 'react'
import InputMask from 'react-input-mask'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
// import Reaptcha from 'reaptcha';
import Reaptcha from 'reaptcha'
import { useRecaptcha } from '../../hooks/recaptcha'

// // Stores
// import { MeStoreContext, useMeActions } from '../../stores/me';

// Components
import arrowLeft from 'assets/svg/home/arrow-left.svg'

// Utils
import { SignUpFormData, signUpFormValidationSchema } from './sign-up.schema'
import { AuthContent } from 'component-library/auth-content'
import { AuthForm } from 'component-library/auth-form'
import { Breadcrumb } from 'component-library/breadcrumb'
import { Button } from 'component-library/button'
import { Checkbox } from 'component-library/checkbox'
import { Input } from 'component-library/input'

type SignUpPageProps = {
  errorMessage?: string
  handleBreadcrumbClick: () => void
  handleSignUpSubmit: (formData: SignUpFormData, recaptchaToken: string) => void
}

export const SignUpPage: FunctionComponent<SignUpPageProps> = ({
  errorMessage,
  handleBreadcrumbClick,
  handleSignUpSubmit
}) => {
  const { recaptchaRef, recaptchaToken, handleRender, handleVerify } =
    useRecaptcha()

  const { handleSubmit, formState, control, watch } = useForm<SignUpFormData>({
    resolver: zodResolver(signUpFormValidationSchema),
    defaultValues: {
      signUp: {
        phone: '',
        name: '',
        email: '',
        confirmEmail: '',
        password: '',
        termsAccepted: false
      }
    }
  })

  const formGeneralInformations = watch('signUp')

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit((submitEvent) =>
        handleSignUpSubmit(submitEvent, recaptchaToken ?? '')
      )()
    }
  }

  return (
    <Fragment>
      <Reaptcha
        ref={recaptchaRef}
        sitekey="6LdgwbUZAAAAANjYJr6SUXlhAFcAxI0zyBlBQE9d"
        size="invisible"
        onRender={handleRender}
        onVerify={handleVerify}
      />

      <AuthContent
        handleSubmit={handleSubmit((event) =>
          handleSignUpSubmit(event, recaptchaToken ?? '')
        )}
        breadcrumbElement={
          <Breadcrumb
            label="Voltar"
            backIcon={arrowLeft}
            handleClick={handleBreadcrumbClick}
          />
        }
        title="Cadastro"
        description="Seja parte da nossa incrível comunidade!"
        formComposition={
          <AuthForm
            inputElements={
              <Fragment>
                <Controller
                  name="signUp.name"
                  control={control}
                  render={({ onChange }) => (
                    <Input
                      id="name"
                      placeholder="Seu nome"
                      value={formGeneralInformations.name ?? ''}
                      onChange={(event) => onChange(event.target.value)}
                      onKeyDown={handleKeyDown}
                      disabled={formState.isSubmitting}
                      {...(formState.errors?.signUp?.name && {
                        variant: 'error',
                        errorMessage: formState.errors?.signUp?.name.message
                      })}
                    />
                  )}
                />

                <Controller
                  name="signUp.email"
                  control={control}
                  render={({ onChange }) => (
                    <Input
                      id="email"
                      placeholder="Seu e-mail"
                      isEmail
                      value={formGeneralInformations.email ?? ''}
                      onKeyDown={handleKeyDown}
                      onChange={(event) => onChange(event.target.value)}
                      disabled={formState.isSubmitting}
                      {...(formState.errors?.signUp?.email && {
                        variant: 'error',
                        errorMessage: formState.errors?.signUp?.email.message
                      })}
                    />
                  )}
                />

                <Controller
                  name="signUp.confirmEmail"
                  control={control}
                  render={({ onChange }) => (
                    <Input
                      id="confirmEmail"
                      placeholder="Confirmar e-mail"
                      disabled={formState.isSubmitting}
                      isEmail
                      onKeyDown={handleKeyDown}
                      value={formGeneralInformations.confirmEmail ?? ''}
                      onChange={(event) => onChange(event.target.value)}
                      onPaste={(e) => e.preventDefault()}
                      {...(formState.errors?.signUp?.confirmEmail && {
                        variant: 'error',
                        errorMessage:
                          formState.errors?.signUp?.confirmEmail.message
                      })}
                    />
                  )}
                />

                <Controller
                  name="signUp.phone"
                  control={control}
                  render={(field) => (
                    <InputMask
                      id="phone"
                      mask="(99) 99999-9999"
                      value={field.value}
                      onChange={field.onChange}
                      disabled={formState.isSubmitting}
                      maskChar={null}
                    >
                      <Input
                        id="phone"
                        disabled={formState.isSubmitting}
                        placeholder="Celular"
                        onKeyDown={handleKeyDown}
                        {...(formState.errors.signUp?.phone && {
                          variant: 'error',
                          errorMessage: formState.errors.signUp?.phone.message
                        })}
                      />
                    </InputMask>
                  )}
                />

                <Controller
                  name="signUp.password"
                  control={control}
                  render={({ onChange }) => (
                    <Input
                      id="password"
                      placeholder="Sua senha"
                      type="password"
                      hasInputTypeToggle
                      onKeyDown={handleKeyDown}
                      disabled={formState.isSubmitting}
                      value={formGeneralInformations.password ?? ''}
                      onChange={(event) => onChange(event.target.value)}
                      {...(formState.errors?.signUp?.password && {
                        variant: 'error',
                        errorMessage: formState.errors?.signUp?.password.message
                      })}
                    />
                  )}
                />

                <Controller
                  name="signUp.termsAccepted"
                  control={control}
                  render={({ onChange }) => (
                    <Checkbox
                      textElements={
                        <p>
                          Li e aceito os{' '}
                          <a
                            href="https://www.proenem.com.br/termos-de-uso"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            termos de uso
                          </a>
                          .
                        </p>
                      }
                      checked={!!formGeneralInformations.termsAccepted}
                      onChange={(event) => onChange(event.target.checked)}
                      {...(formState.errors?.signUp?.termsAccepted && {
                        variant: 'error',
                        errorMessage:
                          formState.errors?.signUp?.termsAccepted.message
                      })}
                    />
                  )}
                />
              </Fragment>
            }
            actionElement={
              <Button
                isFullyAdaptative
                variant="primary"
                label="Cadastrar"
                type="submit"
                id="sendForm"
                borderType="small"
                isLoading={formState.isSubmitting}
              />
            }
            {...(!!errorMessage && { errorMessage })}
          />
        }
      />
    </Fragment>
  )
}
