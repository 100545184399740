// Dependencies
import styled from 'styled-components'

export const Container = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 64px 32px 168px 32px;
  gap: 40px;
  background: #fff;

  @media (min-width: 768px) {
    align-items: flex-start;
    padding: 32px;
    border-radius: 20px;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
    gap: 32px;
  }
`

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2px;
`

export const Title = styled.p`
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: #232326;
  margin-top: 68px;

  @media (min-width: 768px) {
    margin-top: 0;
  }

  @media (min-width: 1440px) {
    font-size: 32px;
    font-weight: 800;
    line-height: 44px;
  }
`

export const Subtitle = styled.p`
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #83899e;
`

export const Content = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;

  > form {
    width: 100%;
  }
`

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > .pdg-button {
    color: #83899e;
    text-align: center;
    font-family: 'Nunito Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-bottom: none;
  }
`
