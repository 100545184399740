import styled, { css } from 'styled-components'

export const Container = styled.div<{ isActive?: boolean }>`
  position: relative;

  .toggle-checkbox {
    display: none;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      .toggle-checkbox + .toggle-label:before {
        animation-name: switch;
        animation-duration: 350ms;
        animation-fill-mode: forwards;
      }
    `}

  ${({ isActive }) =>
    !isActive &&
    css`
      .toggle-label &:before {
        animation-name: reverse;
        animation-duration: 350ms;
        animation-fill-mode: forwards;
        transition: all 350ms ease-in;
        cursor: pointer;

        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #9C9FB0;

        position: absolute;
        top: 1.8px;
      }
    `}

  .toggle-label {
    display: flex;
    width: 32px;
    height: 20px;
    border-radius:40px;

    &:before {
      animation-name: reverse;
      animation-duration: 350ms;
      animation-fill-mode: forwards;
      transition: all 350ms ease-in;
      cursor: pointer;
      content: '';
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #fff;

      position: absolute;
      top: 1.8px;
    }

    @keyframes switch {
      0% {
        left: 2px;
      }

      60% {
        left: 4px;
        width: 16px;
      }

      100% {
        left: 13px;
        width: 16px;
      }
    }

    @keyframes reverse {
      0% {
        left: 13px;
        width: 16px;
      }

      60% {
        left: 10px;
        width: 16px;
      }

      100% {
        left: 2px;
      }
    }
  }
`
