// Dependencies
import React, { FunctionComponent } from 'react'

// Types
import { SelectorItemProps, SelectorProps } from './selector.types'

// Styles
import { Container, Label, SelectorItemContainer } from './selector.styles'
import { ConditionallyRender } from 'components/utilities/conditionally-render'
import { Icon } from 'component-library/icon'

export const Selector: FunctionComponent<SelectorProps> = ({
  itemsComposition
}) => {
  return <Container>{itemsComposition}</Container>
}

export const SelectorItem: FunctionComponent<SelectorItemProps> = ({
  label,
  isSelected,
  handleClick
}) => {
  return (
    <SelectorItemContainer isSelected={!!isSelected} onClick={handleClick}>
      <Label>{label}</Label>

      <ConditionallyRender
        shouldRender={!!isSelected}
        content={<Icon name="check" color="#157050" size={16} />}
      />
    </SelectorItemContainer>
  )
}
