// Dependencies
import { ConditionallyRender } from 'components/utilities/conditionally-render'
import React, { FunctionComponent } from 'react'

// Styles
import {
  DayLabel,
  DayValue,
  TabContainer,
  TabItemContainer
} from './tab.styles'

// Types
import { TabProps, TabItemProps } from './tab.types'

export const Tab: FunctionComponent<TabProps> = ({ tabItemsComposition }) => {
  return <TabContainer>{tabItemsComposition}</TabContainer>
}

export const TabItem: FunctionComponent<TabItemProps> = ({
  isActive,
  dayLabel,
  dayValue,
  handleClick
}) => {
  return (
    <TabItemContainer isActive={isActive} onClick={handleClick}>
      <DayLabel>{dayLabel}</DayLabel>

      <ConditionallyRender
        maximumBreakpoint="tablet"
        content={<DayValue>•</DayValue>}
      />

      <ConditionallyRender
        minimumBreakpoint="tabletAuxiliary"
        content={<DayValue>•</DayValue>}
      />

      <DayValue>{dayValue}</DayValue>
    </TabItemContainer>
  )
}
