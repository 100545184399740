// Dependencies
import styled from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 64px 32px 168px 32px;
  gap: 40px;
  background: #c11636;

  @media (min-width: 768px) {
    align-items: flex-start;
    padding: 32px;
    border-radius: 20px;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
    gap: 32px;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const LogoImage = styled.img`
  display: block;
`

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 396px;
`

export const Title = styled.p`
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
`

export const Subtitle = styled.p`
  font-family: 'Nunito', sans-serif;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
`

export const SelectorWrapper = styled.div`
  width: 100%;

  display: flex;
  align-self: flex-start;
  flex-direction: column;
  gap: 16px;
`

export const ButtonCompositionWrapper = styled.div`
  width: 100%;

  ${MediaQueries.medium} {
    display: flex;
    justify-content: flex-end;
  }
`

export const SelectorLabel = styled.p`
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #fff;
`
