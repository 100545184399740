// Dependencies
import React, { Fragment, useContext, useState } from 'react'

// Utils
import { data } from './gabaritos.data'
import { EditionDaysUnion } from './gabaritos.types'
import { useGabaritos } from './gabaritos.hook'
import {
  getFormattedTitle,
  getQuestionNumber,
  getQuestionsColumnsLength,
  getResultsCardName,
  getResultsTotalHits
} from './gabaritos.helpers'

// Componentes
import Layout from '../../components/Layout'
import ToggleGabaritos from '../../components/ToggleUseComponent'
import { Button } from 'component-library/button'
import { GabaritoWorkspace } from 'component-library/gabarito-workspace'
import { Select } from 'component-library/select/select'
import { Label } from 'component-library/label/label'
import { PictureInPicture } from 'component-library/picture-in-picture/picture-in-picture'
import { Question, QuestionColumn } from 'component-library/question/question'
import { Modal } from 'components/Modal'

// Assets
import { WelcomeUserCard } from 'component-library/welcome-user-card'
import { Tab, TabItem } from 'component-library/tab'
import {
  ResultsCard,
  ResultsCardItem,
  ResultsCardItemSkeleton
} from 'component-library/results-card'
import { Icon } from 'component-library/icon'
import { ProgressBar } from 'component-library/progress-bar/progress-bar'
import { Selector, SelectorItem } from 'component-library/selector'
import { AnswerExamSection } from 'component-library/answer-exam-section'
import { ConditionallyRender } from 'components/utilities/conditionally-render'
import { useWindowDimensions } from 'hooks/windows-dimensions'
import { ternary } from 'utils/ternary'
import { AuthSelector } from 'component-library/auth-selector'

import logoWhite from 'assets/svg/home/logo-white.svg'
import person from 'assets/svg/home/person.svg'
import file from 'assets/svg/home/file.svg'

import {
  AuthCardSelector,
  AuthCardSelectorItem
} from 'component-library/auth-card-selector'
import SignInPage from 'component-library/sign-in'
import SignUpPage from 'component-library/sign-up'
import { useModalContext } from 'contexts/modal'
import { Link } from 'component-library/link'

export const AlternativeOptions = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E'
} as const

export type AlternativeOption =
  (typeof AlternativeOptions)[keyof typeof AlternativeOptions]

export const Gabaritos = () => {
  const { width: windowWidth } = useWindowDimensions()
  const [isResultsCardExpanded, setIsResultsCardExpanded] = useState(true)

  const { isOpened, setIsOpened } = useModalContext()

  const {
    filters,
    selectedEnemEdition,
    selectedEnemEditionData,
    userData,
    handleSignOut,
    getEditionLabel,
    selectedEditionDayIndex,
    handleSelectTab,
    dayResults,
    colorOptions,
    selectSelectedColor,
    handleSelectColor,
    selectedEditionDayLabel,
    selectSelectedLanguage,
    handleSelectLanguage,
    editionDayResponses,
    selectedLanguage,
    selectedColor,
    editionDayUserResponses,
    editionDayResponsesStart,
    handleSelectQuestion,
    handleSelectEnemEdition,
    handleCopyToClipboard,
    hasUnansweredEditionDayQuestions,
    handleOpeningSisuSite,
    hasVisibleCorrection,
    handleSignInSubmit,
    handleSignUpSubmit,
    setHasVisibleCorrection,
    setUserAuthMethod,
    userAuthMethod,
    currentStepAuthForm,
    authData,
    setCurrentStepAuthForm
  } = useGabaritos()

  if (!filters || !selectedEnemEdition || !selectedEnemEditionData) {
    return null
  }

  const numColumns = getQuestionsColumnsLength(windowWidth)

  const chooseContent = (
    <AuthSelector
      logoSrc={logoWhite}
      title={getFormattedTitle(userData?.name ?? '')}
      subtitle="Bem-vindo a ferramenta de gabaritos do Proenem."
      selectorLabel="Para continuar selecione uma das opções:"
      selectorComposition={
        <AuthCardSelector
          selectorItemsCompositions={
            <Fragment>
              <AuthCardSelectorItem
                title="Já tenho conta no Pro"
                isActive={userAuthMethod === 'login'}
                description="Alunos Proenem ou Promedicina"
                headerIcon={person}
                handleClick={() => setUserAuthMethod('login')}
              />

              <AuthCardSelectorItem
                title="Ainda não sou cadastrado"
                isActive={userAuthMethod === 'register'}
                description="Cadastre-se e tenha acesso ao gabarito"
                headerIcon={file}
                handleClick={() => setUserAuthMethod('register')}
              />
            </Fragment>
          }
        />
      }
      buttonComposition={
        <Button
          label="Continuar"
          borderType={windowWidth < 768 ? 'small' : 'large'}
          variant="white"
          isFullyAdaptative={windowWidth < 768}
          handleClick={() => setCurrentStepAuthForm(userAuthMethod as any)}
        />
      }
    />
  )

  const modalContent = ternary([
    [currentStepAuthForm === 'choose', chooseContent],
    [
      currentStepAuthForm === 'login',
      <SignInPage
        errorMessage={authData.errorMessage}
        handleBreadcrumbClick={() => setCurrentStepAuthForm('choose')}
        handleSignInSubmit={handleSignInSubmit}
      />
    ],
    [
      currentStepAuthForm === 'register',
      <SignUpPage
        errorMessage={authData.errorMessage}
        handleSignUpSubmit={handleSignUpSubmit}
        handleBreadcrumbClick={() => setCurrentStepAuthForm('choose')}
      />
    ]
  ])

  const resultsComposition = (
    <ResultsCard
      isExpanded={windowWidth >= 768 || !!isResultsCardExpanded}
      expandActionElement={
        <Icon
          name={isResultsCardExpanded ? 'chevronUp' : 'chevronDown'}
          size={24}
          color="#fff"
          handleClick={() => setIsResultsCardExpanded(!isResultsCardExpanded)}
        />
      }
      resultsLabel="Resultado"
      simulateSisuTitle="Quer simular o SISU?"
      simulateSisuDescription={
        windowWidth >= 768
          ? 'Utilize as notas com TRI e simule suas chances de entrar na universidade com nosso simulador.'
          : 'Simule as chances de entrar na universidade pelo SiSU.'
      }
      totalHits={getResultsTotalHits(dayResults?.totalResults)}
      resultsComposition={
        <Fragment>
          <ConditionallyRender
            shouldRender={!userData}
            content={Array.from({ length: 2 }).map((_, index) => (
              <ResultsCardItem
                key={`results-card-non-authenticated-${index}`}
                name={getResultsCardName(
                  selectedEditionDayLabel ?? 'dia-1',
                  index
                )}
                hits="0/45"
                shouldDisplayTriScore={false}
                estimatedTriScore="TRI estimada: 000 ~ 000"
                progressBarElement={<ProgressBar progress={0} />}
              />
            ))}
          />
          <ConditionallyRender
            shouldRender={
              (!!dayResults &&
                !!userData &&
                !dayResults?.subjectResults.length) ||
              (!!userData && !dayResults)
            }
            content={Array.from({ length: 2 }).map((_, index) => (
              <ResultsCardItemSkeleton
                key={`results-card-item-skeleton-${index}`}
              />
            ))}
          />
          <ConditionallyRender
            shouldRender={!!dayResults?.subjectResults.length}
            content={(dayResults?.subjectResults ?? []).map(
              (subject, index) => (
                <ResultsCardItem
                  key={subject.label}
                  name={subject.label}
                  hits={`${subject.result.hits} / ${subject.result.total}`}
                  shouldDisplayTriScore={!!subject.tri}
                  estimatedTriScore={`TRI estimada: ${
                    isNaN(subject.tri?.min ?? 0) ||
                    subject.tri?.min === undefined
                      ? '000'
                      : subject.tri?.min
                  } ~ ${
                    isNaN(subject.tri?.max ?? 0) ||
                    subject.tri?.max === undefined
                      ? '000'
                      : subject.tri?.max
                  }`}
                  progressBarElement={
                    <ProgressBar
                      progress={
                        (subject.result.hits / subject.result.total) * 100
                      }
                    />
                  }
                />
              )
            )}
          />
        </Fragment>
      }
      simulateSisuActionElement={
        <Button
          label="Simule agora"
          variant="white"
          isFullyAdaptative={windowWidth >= 1024}
          handleClick={handleOpeningSisuSite}
        />
      }
    />
  )

  return (
    <Layout disableFixed={true} screen="gabaritos">
      <Modal
        isOpened={!!isOpened}
        closeIconVariant={currentStepAuthForm === 'choose' ? 'choose' : 'form'}
        closeIconElement={
          <Icon
            name="close"
            color={currentStepAuthForm === 'choose' ? '#fff' : '#7C8195'}
            size={24}
            handleClick={() => setIsOpened(false)}
          />
        }
        handleOutsideClick={() => {
          setIsOpened(false)
          setCurrentStepAuthForm('choose')
        }}
        content={modalContent}
      />

      <GabaritoWorkspace
        welcomeUserComposition={
          <WelcomeUserCard
            shouldDisplayOnlyUnofficialLabel={!userData}
            actionElement={
              <Link label="Sair" variant="logout" handleClick={handleSignOut} />
            }
            unofficialLabel="Este é um gabarito extraoficial"
            separatorLabel="/"
            usernameLabel={`Olá, ${userData?.name}`}
          />
        }
        sectionTitle={`GABARITO ENEM ${getEditionLabel}`}
        videoPlayerElement={
          <PictureInPicture
            iframeElement={
              <iframe
                src={
                  selectedEnemEditionData?.[selectedEditionDayIndex]?.videoUrl
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            }
            resultsComposition={resultsComposition}
          />
        }
        resultsSectionComposition={
          <Fragment>
            <Tab
              tabItemsComposition={filters.days.map((editionDay, index) => (
                <TabItem
                  key={editionDay.key}
                  dayLabel={`Dia ${index + 1}`}
                  dayValue={selectedEnemEditionData[index]?.name?.replace(
                    /\bde\b\s?/g,
                    ''
                  )}
                  isActive={selectedEditionDayLabel === editionDay.key}
                  handleClick={() => {
                    handleSelectTab(editionDay.key as EditionDaysUnion, index)
                  }}
                />
              ))}
            />

            {resultsComposition}
          </Fragment>
        }
        answerExamComposition={
          <AnswerExamSection
            warningTitle="Atenção"
            warningDescription="Selecione os campos abaixo de acordo com a prova realizada no Enem."
            examOptionsSelectorsComposition={
              <Fragment>
                <Select
                  label="Cor da prova"
                  placeholder="Selecione uma cor"
                  options={colorOptions}
                  selected={selectSelectedColor}
                  onChange={handleSelectColor}
                />

                <ConditionallyRender
                  shouldRender={selectedEditionDayLabel === 'dia-1'}
                  content={
                    <Select
                      label="Idioma"
                      placeholder="Selecione um idioma"
                      options={data.testLanguages}
                      selected={selectSelectedLanguage}
                      onChange={handleSelectLanguage}
                    />
                  }
                />
              </Fragment>
            }
            examActionsComposition={
              <Fragment>
                <ToggleGabaritos
                  onToggleSwitchClicked={() => {
                    if (!!userData) {
                      setHasVisibleCorrection(!hasVisibleCorrection)
                      return
                    }

                    setIsOpened(true)
                  }}
                  activeToggle={hasVisibleCorrection}
                />

                <ConditionallyRender
                  shouldRender={!hasUnansweredEditionDayQuestions}
                  content={
                    <Label
                      label="Copiar gabarito"
                      icon="copy"
                      onLabelClick={handleCopyToClipboard}
                    />
                  }
                />
              </Fragment>
            }
            examYearSelectorComposition={
              <Selector
                itemsComposition={filters.years.map((year) => (
                  <SelectorItem
                    key={`selector-item-${year.key}`}
                    label={year.value}
                    isSelected={year.key === selectedEnemEdition}
                    handleClick={() => handleSelectEnemEdition(year.key)}
                  />
                ))}
              />
            }
            examQuestionsComposition={Array.from({ length: numColumns }).map(
              (_, columnIndex) => (
                <QuestionColumn
                  key={`question-column-${columnIndex}`}
                  content={editionDayUserResponses.split('').map(
                    (question, index) =>
                      columnIndex *
                        Math.ceil(
                          editionDayUserResponses.split('').length / numColumns
                        ) <=
                        index &&
                      index <
                        (columnIndex + 1) *
                          Math.ceil(
                            editionDayUserResponses.split('').length /
                              numColumns
                          ) && (
                        <Question
                          key={`question-${editionDayResponsesStart + index}`}
                          questionNumber={getQuestionNumber(
                            editionDayResponsesStart + index
                          )}
                          correctAlternative={
                            !!editionDayResponses &&
                            (!!selectedLanguage ||
                              selectedEditionDayLabel === 'dia-2') &&
                            !!selectedColor &&
                            data.alternatives.indexOf(
                              editionDayResponses[index]
                            ) > -1
                              ? (editionDayResponses[
                                  index
                                ] as AlternativeOption)
                              : undefined
                          }
                          selectedAlternative={
                            question === '?'
                              ? undefined
                              : (question as AlternativeOption)
                          }
                          isCanceled={
                            !!editionDayResponses &&
                            editionDayResponses[index] === 'X'
                          }
                          hasCorrectionAvailable={
                            !!editionDayResponses &&
                            !!selectedColor &&
                            (!!selectedLanguage ||
                              selectedEditionDayLabel === 'dia-2')
                              ? editionDayResponses[index] !== '?'
                              : undefined
                          }
                          hasVisibleCorrection={
                            !!editionDayResponses &&
                            editionDayResponses[index] !== '?'
                              ? hasVisibleCorrection
                              : false
                          }
                          onQuestionClick={(event: CustomEvent) => {
                            handleSelectQuestion(event, index)
                          }}
                        />
                      )
                  )}
                />
              )
            )}
          />
        }
      />
    </Layout>
  )
}
