// Dependencies
import React, { FunctionComponent } from 'react'

// Types
import { AuthSelectorProps } from './auth-selector.types'

// Styles
import {
  Container,
  LogoImage,
  Title,
  Subtitle,
  SelectorWrapper,
  SelectorLabel,
  TitleWrapper,
  Content,
  ButtonCompositionWrapper
} from './auth-selector.styles'

// Components
import { ConditionallyRender } from 'components/utilities/conditionally-render'

export const AuthSelector: FunctionComponent<AuthSelectorProps> = ({
  title,
  subtitle,
  logoSrc,
  selectorLabel,
  selectorComposition,
  buttonComposition
}) => {
  return (
    <Container className="pdg-auth-selector">
      <LogoImage src={logoSrc} />

      <Content>
        <TitleWrapper>
          <Title>{title}</Title>

          <Subtitle>{subtitle}</Subtitle>
        </TitleWrapper>

        <SelectorWrapper>
          <SelectorLabel>{selectorLabel}</SelectorLabel>

          {selectorComposition}
        </SelectorWrapper>

        <ConditionallyRender
          shouldRender={!!buttonComposition}
          content={
            <ButtonCompositionWrapper>
              {buttonComposition}
            </ButtonCompositionWrapper>
          }
        />
      </Content>
    </Container>
  )
}
