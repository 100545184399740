// Dependencies
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2px;
`

export const SubLabel = styled.p`
  color: #7c8195;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`

export const Label = styled.p`
  color: #000;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`

export const UnofficialAndActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const SEOLabel = styled.h2`
  color: #7c8195;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`
