import styled, { css } from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

type ContainerStyleProps = {
  isCanceled: boolean
}

export const Container = styled.div<ContainerStyleProps>`
  width: 100%;

  > div.content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    position: relative;
    border: 1px solid #f0f1f7;
    background: #fafafc;

    > .pdg-tooltip {
      position: absolute;
      left: -8px;
      top: 18px;
    }

    ${({ isCanceled }) =>
      !isCanceled &&
      css`
        padding: 8px 16px;
      `}

    ${({ isCanceled }) =>
      !!isCanceled &&
      css`
        padding-left: 16px;
      `}


    > div.question-number {
      margin-right: 12px;
      color: #000;
      text-align: center;
      font-family: 'Source Sans 3';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }

    > div.correct-question-number {
      color: #1e9369;
    }

    > div.selected-without-show-answer {
    }

    > div.wrong-question-number {
      color: #de2a2c;
    }

    > div.alternative {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      border: 1px solid #ced0dd;
      background: #fff;

      color: #000;
      text-align: center;
      font-family: 'Source Sans 3';
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;

      cursor: pointer;
    }

    > div.question-without-correct-answer {
      border-color: #ced0dd;
      background: #ced0dd;
    }

    > div.correct-alternative {
      background: #28b583;
      border-color: #28b583;
      color: #fff6f5;
    }

    > div.wrong-alternative {
      background: #e85153;
      color: #fff6f5;
      border-color: #e85153;
    }

    > div.is-right-alternative-of-wrong-question {
      border-color: #28b583;
      color: #28b583;
      background: #d1f3e7;
    }

    > div.hover:hover {
      background: transparent;
      border: 2px solid #3a3e47;
      color: #000;
      transition: all 0.2ms ease-in-out;
    }

    > div.question-without-correct-answer:hover {
      border-color: #ced0dd;
      background: #ced0dd;
    }

    > div.correct-alternative:hover {
      background: #28b583;
      border-color: #28b583;
      color: #fff6f5;
    }

    > div.wrong-alternative:hover {
      background: #e85153;
      color: #fff6f5;
      border-color: #e85153;
    }

    > div.is-right-alternative-of-wrong-question:hover {
      border: 1px solid #28b583;
      color: #28b583;
      background: #d1f3e7;
    }

    > div.with-correction-but-answers:hover {
      border: 2px solid #3A3E47;
      background: #fff;
      color: #000;
    }

    > div.is-right-alternative-of-preview-question{
      border: 1px solid #4b9eff;
      background: #edf5ff;
      color: #4b9eff;
    }

    > div.is-right-alternative-of-preview-question:hover {
      border: 2px solid #28b583;
      background: #a5e6cf;
      color: #28b583;
    }

    > div.correction-status {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -8px;
      left: -1.2px;
      padding: 2px 4px;
      border-radius: 2px 2px 2px 0px;

      > p {
        margin-left: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        color: #000000;
      }
    }

    div.correction-status-awaiting-correction {
      background: #ff9f18;
    }

    div.correction-status-correction-available,
    div.correction-status-correction-preview {
      background: #ced0dd;
    }
  }

  > div.correct-content {
    background: #d1f3e7;
  }

  > div.wrong-content {
    background: #fcd1d1;
  }

  > div.awaiting-correction {
    border: 1px solid #fddea4;
  }

  > div.correction-available {
    border: 1px solid #ddd2df;
  }
`

type IconWrapperStyleProps = {
  backgroundColor: string
}

export const IconWrapper = styled.div<IconWrapperStyleProps>`
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;

  background: ${({ backgroundColor }) => backgroundColor};
`

export const QuestionColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CancelledQuestionWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: 16px;
  height: 56px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #fff;
`

export const CancelledQuestionLabel = styled.p`
  color: #25272d;
  text-align: center;
  font-family: 'Source Sans 3';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`
