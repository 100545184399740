// Dependencies
import styled, { css, keyframes } from 'styled-components'

// Types
import { ButtonProps } from './button.types'

type ContainerStyleProps = {
  size: 'large' | 'small'
  borderType: ButtonProps['borderType']
  backgroundColor?: string
  isFullyAdaptative: boolean
  disabled?: boolean
  borderColor?: string
  hasCursorPointer: boolean
}

export const Container = styled.button<ContainerStyleProps>`
  width: max-content;
  border: 1px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: relative;
  transition: all 0.3s;
  opacity: 1;

  flex-shrink: 0;

  ${({ backgroundColor }) =>
    !!backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}

  ${({ backgroundColor, borderColor }) =>
    !!backgroundColor &&
    !borderColor &&
    css`
      border-color: ${backgroundColor};
    `}

  ${({ backgroundColor, borderColor }) =>
    !backgroundColor &&
    !borderColor &&
    css`
      background-color: transparent;
      border-color: transparent;
    `}

  ${({ borderColor }) =>
    !!borderColor &&
    css`
      border-color: ${borderColor};
    `}

  ${({ hasCursorPointer }) =>
    hasCursorPointer &&
    css`
      cursor: pointer;
    `}

  ${({ hasCursorPointer }) =>
    !hasCursorPointer &&
    css`
      cursor: default;
    `}

  ${({ isFullyAdaptative }) =>
    isFullyAdaptative &&
    css`
      width: 100% !important;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}

  ${({ borderType }) =>
    borderType === 'large' &&
    css`
      border-radius: 100px;
    `}

  ${({ borderType }) =>
    borderType === 'small' &&
    css`
      border-radius: 4px;
    `}


  ${({ borderType }) =>
    borderType === 'medium' &&
    css`
      border-radius: 8px;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 8px;
    `}

  ${({ size }) =>
    size === 'large' &&
    css`
      padding: 10px 20px;
    `}
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
`

type LabelStyleProps = {
  textColor: string
}

export const Label = styled.div<LabelStyleProps>`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  ${({ textColor }) =>
    textColor &&
    css`
      color: ${textColor};
    `}
`

const ellipsis1 = keyframes`
   0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`

const ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
`

const ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 24px;
`

type LoaderStyleProps = {
  color: string
}

export const Loader = styled.div<LoaderStyleProps>`
  display: flex;
  position: absolute;

  > div {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 13px;

    > div {
      position: absolute;
      top: 3.25px;
      width: 6.5px;
      height: 6.5px;
      border-radius: 50%;
      background: ${({ color }) => color};
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    > div:nth-child(1) {
      left: 4px;
      animation: ${ellipsis1} 0.6s infinite;
    }

    > div:nth-child(2) {
      left: 4px;
      animation: ${ellipsis2} 0.6s infinite;
    }

    > div:nth-child(3) {
      left: 16px;
      animation: ${ellipsis2} 0.6s infinite;
    }

    > div:nth-child(4) {
      left: 28px;
      animation: ${ellipsis3} 0.6s infinite;
    }
  }
`
