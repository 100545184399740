import React, { memo } from 'react'

//styles
import { Wrapper, Show } from './styles'

//components
import { ToggleSwitch } from 'component-library/toggle-switch/toggle-switch'

interface IProps {
  activeColor?: string
  onToggleSwitchClicked: () => void
  activeToggle?: boolean
}

const ToggleGabaritos = ({
  activeColor = '#DF1D3D',
  activeToggle,
  onToggleSwitchClicked,
}: IProps) => {
  return (
    <Wrapper>
      <ToggleSwitch
        activeColor={activeColor}
        handleClick={onToggleSwitchClicked}
        isActive={!!activeToggle}
      />
      <Show>Exibir respostas</Show>
    </Wrapper>
  )
}

export default memo(ToggleGabaritos)
