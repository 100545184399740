// Dependencies
import React, { FunctionComponent } from 'react';

// Types
import { BreadcrumbProps } from './breadcrumb.types';

// Styles
import { Container, Icon, Label } from './breadcrumb.styles';

export const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({
  label,
  backIcon,
  handleClick,
}) => {
  return (
    <Container onClick={handleClick}>
      <Icon src={backIcon} />

      <Label>{label}</Label>
    </Container>
  );
};
