// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.6488 19.8746C20.221 17.4062 18.0207 15.6362 15.4529 14.7971C16.723 14.041 17.7099 12.8888 18.2618 11.5176C18.8138 10.1463 18.9004 8.63175 18.5083 7.20652C18.1161 5.78129 17.267 4.52418 16.0913 3.62824C14.9156 2.7323 13.4783 2.24707 12.0001 2.24707C10.5219 2.24707 9.08457 2.7323 7.90884 3.62824C6.73312 4.52418 5.884 5.78129 5.49189 7.20652C5.09978 8.63175 5.18635 10.1463 5.73831 11.5176C6.29027 12.8888 7.2771 14.041 8.54726 14.7971C5.97945 15.6352 3.77913 17.4052 2.35132 19.8746C2.29896 19.96 2.26423 20.055 2.24918 20.154C2.23413 20.253 2.23906 20.3541 2.26369 20.4511C2.28831 20.5482 2.33213 20.6394 2.39256 20.7192C2.45299 20.7991 2.52881 20.8661 2.61553 20.9162C2.70226 20.9663 2.79814 20.9985 2.89752 21.0109C2.99689 21.0234 3.09776 21.0158 3.19415 20.9886C3.29055 20.9614 3.38053 20.9152 3.45878 20.8527C3.53703 20.7902 3.60197 20.7126 3.64976 20.6246C5.41601 17.5721 8.53788 15.7496 12.0001 15.7496C15.4623 15.7496 18.5841 17.5721 20.3504 20.6246C20.3982 20.7126 20.4631 20.7902 20.5414 20.8527C20.6196 20.9152 20.7096 20.9614 20.806 20.9886C20.9024 21.0158 21.0032 21.0234 21.1026 21.0109C21.202 20.9985 21.2979 20.9663 21.3846 20.9162C21.4713 20.8661 21.5471 20.7991 21.6076 20.7192C21.668 20.6394 21.7118 20.5482 21.7365 20.4511C21.7611 20.3541 21.766 20.253 21.751 20.154C21.7359 20.055 21.7012 19.96 21.6488 19.8746ZM6.75007 8.99962C6.75007 7.96127 7.05798 6.94624 7.63486 6.08288C8.21173 5.21952 9.03167 4.54661 9.99098 4.14925C10.9503 3.75189 12.0059 3.64793 13.0243 3.8505C14.0427 4.05307 14.9782 4.55308 15.7124 5.28731C16.4466 6.02154 16.9466 6.957 17.1492 7.9754C17.3518 8.9938 17.2478 10.0494 16.8504 11.0087C16.4531 11.968 15.7802 12.788 14.9168 13.3648C14.0535 13.9417 13.0384 14.2496 12.0001 14.2496C10.6081 14.2481 9.27365 13.6945 8.2894 12.7103C7.30516 11.726 6.75156 10.3916 6.75007 8.99962Z" />
  </svg>
)
