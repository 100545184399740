// Dependencies
import React, { FunctionComponent } from 'react'

// Types
import { AuthContentProps } from './auth-content.types'

// Styles
import {
  Container,
  Subtitle,
  Title,
  FooterWrapper,
  TitleWrapper,
  Content
} from './auth-content.styles'

export const AuthContent: FunctionComponent<AuthContentProps> = ({
  title,
  description,
  breadcrumbElement,
  formComposition,
  footerElement,
  handleSubmit
}) => {
  return (
    <Container onSubmit={handleSubmit}>
      <TitleWrapper>
        {breadcrumbElement}

        <Title>{title}</Title>

        <Subtitle>{description}</Subtitle>
      </TitleWrapper>

      <Content>
        {formComposition}

        <FooterWrapper>{footerElement}</FooterWrapper>
      </Content>
    </Container>
  )
}
