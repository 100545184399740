// Dependencies
import React, { FunctionComponent, useState } from 'react';

// Types
import { TooltipProps } from './tooltip.types';

// Styles
import { TooltipContainer, TooltipContentWrapper, TooltipLabel } from './tooltip.styles';

// Components
import { ConditionallyRender } from 'components/utilities/conditionally-render';


export const Tooltip: FunctionComponent<TooltipProps> = ({
  hasArrow = true,
  shouldDisplayTooltip = true,
  arrowGuidance,
  openedContentPositionAdjustment,
  openedContentWidth,
  tooltipContent,
  contentElement,
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <TooltipContainer
      className="pdg-tooltip"
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      {contentElement}

      <ConditionallyRender
        shouldRender={!!isTooltipVisible && !!shouldDisplayTooltip}
        content={
          <TooltipContentWrapper
            hasArrow={hasArrow}
            arrowGuidance={arrowGuidance}
            width={openedContentWidth}
            positionAdjustment={openedContentPositionAdjustment}
          >
           <TooltipLabel>
            {tooltipContent}
           </TooltipLabel>
          </TooltipContentWrapper>
        }
      />
    </TooltipContainer>
  );
};
