import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  height: 38px;
  padding: 8px 24px 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #ced0dd;
  background: #fafafc;

  cursor: pointer;

  color: #666b7b;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
`
