// Dependencies
import React, { FunctionComponent, useEffect } from 'react'

// Types
import { LinkProps } from './link.types'

// Styles
import { Container, ContentWrapper, Label } from './link.styles'

// Components
import { ConditionallyRender } from 'components/utilities/conditionally-render'

export const Link: FunctionComponent<LinkProps> = ({
  isLoading,
  isDisabled,
  variant,
  label,
  iconElement,
  handleClick,
  ...linkDefaultProps
}) => {
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Container
      className="pdg-link"
      target={!!handleClick ? '_self' : '_blank'}
      onClick={(ev) => !!handleClick && handleClick(ev)}
      {...linkDefaultProps}
    >
      <ContentWrapper>
        <ConditionallyRender
          shouldRender={!!label}
          content={<Label variant={variant}>{label}</Label>}
        />

        <ConditionallyRender
          shouldRender={!!iconElement}
          content={iconElement}
        />
      </ContentWrapper>
    </Container>
  )
}
