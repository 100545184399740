const defaultUserAlternatives: string =
  '??????????????????????????????????????????????????????????????????????????????????????????'

const defaultResponses: string =
  '                                                                                          '

const sisuCard = {
  title: 'Quer simular o SISU?',
  description:
    'Utilize as notas com TRI e simule as suas chances de entrar na universidade com nosso simulador do SISU.',
  buttonLabel: 'CLIQUE AQUI!'
}

const defaultTestColors = [
  {
    key: 'amarela',
    title: 'Amarela'
  },
  {
    key: 'azul',
    title: 'Azul'
  },
  {
    key: 'cinza',
    title: 'Cinza'
  },
  {
    key: 'branca',
    title: 'Branca'
  }
]

const oldTestColors = [
  ...defaultTestColors,
  {
    key: 'rosa',
    title: 'Rosa'
  }
]

const testColors = [
  ...defaultTestColors,
  {
    key: 'verde',
    title: 'Verde'
  }
]

const testLanguages = [
  {
    key: 'ingles',
    title: 'Inglês'
  },
  {
    key: 'espanhol',
    title: 'Espanhol'
  }
]

const alternatives = ['A', 'B', 'C', 'D', 'E']

export const data = {
  defaultUserAlternatives,
  defaultResponses,
  alternatives,
  sisuCard,
  oldTestColors,
  testColors,
  testLanguages
}
