// Dependencies
import React, { FunctionComponent, useEffect } from 'react'

// Types
import { ModalProps } from './modal.types'

// Styles
import {
  CloseIconElementWrapper,
  Container,
  Content,
  OverlayContainer
} from './modal.styles'
import { ConditionallyRender } from 'components/utilities/conditionally-render'

export const Modal: FunctionComponent<ModalProps> = ({
  isOpened = false,
  content,
  closeIconElement,
  closeIconVariant,
  handleOutsideClick
}) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <Container isOpened={isOpened}>
      <ConditionallyRender
        minimumBreakpoint="tablet"
        content={
          <OverlayContainer
            onClick={() => !!handleOutsideClick && handleOutsideClick()}
          />
        }
      />

      <Content>
        <CloseIconElementWrapper variant={closeIconVariant}>
          {closeIconElement}
        </CloseIconElementWrapper>

        {content}
      </Content>
    </Container>
  )
}
