// Dependencies
import styled, { css } from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

type ContainerStyleProps = {
  isOpened: boolean
}

export const Container = styled.div<ContainerStyleProps>`
  display: ${({ isOpened }) => (isOpened ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
`

type CloseIconElementWrapperStyleProps = {
  variant: 'choose' | 'form'
}

export const CloseIconElementWrapper = styled.div<CloseIconElementWrapperStyleProps>`
  position: absolute;
  right: 32px;
  top: 32px;

  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;

  border-radius: 100px;

  ${({ variant }) =>
    variant === 'choose' &&
    css`
      background: #df1d3d;
    `}

  ${({ variant }) =>
    variant === 'form' &&
    css`
      background: #f0f1f7;
    `}
`

export const Content = styled.div`
  z-index: 5;
  width: 100%;
  height: 100%;
  position: relative;

  ${MediaQueries.medium} {
    width: 600px;
    height: auto;
  }
`

export const OverlayContainer = styled.div`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 35, 38, 0.8);
`
