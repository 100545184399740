// Dependencies
import styled from 'styled-components';

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`;

export const CheckboxItem = styled.input.attrs({ type: 'checkbox' })`
  accent-color: #C11636;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 8px;

  > p {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #4d4d63;

    > a {
      text-decoration: underline;
      font-family: 'Nunito', sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: #4d4d63;
    }
  }
`;

export const ErrorMessage = styled.p`
  font-family: 'Source Sans 3', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #b41f21;
`;
