// Dependencies
import React, { FunctionComponent } from 'react'

// Types
import { GabaritoWorkspaceProps } from './gabarito-workspace.types'

// Styles
import {
  Container,
  Content,
  ResultsSectionWrapper,
  SectionTitle,
  SectionTitleWrapper,
  VideoPlayerAndResultsWrapper
} from './gabarito-workspace.styles'

export const GabaritoWorkspace: FunctionComponent<GabaritoWorkspaceProps> = ({
  sectionTitle,
  welcomeUserComposition,
  answerExamComposition,
  resultsSectionComposition,
  videoPlayerElement
}) => {
  return (
    <Container>
      <SectionTitleWrapper>
        <SectionTitle>{sectionTitle}</SectionTitle>

        {welcomeUserComposition}
      </SectionTitleWrapper>

      <Content>
        <VideoPlayerAndResultsWrapper>
          {videoPlayerElement}

          <ResultsSectionWrapper>
            {resultsSectionComposition}
          </ResultsSectionWrapper>
        </VideoPlayerAndResultsWrapper>

        {answerExamComposition}
      </Content>
    </Container>
  )
}
