// Dependencies
import React, { Fragment, FunctionComponent, useEffect } from 'react'

// Types
import { ButtonProps } from './button.types'

// Styles
import {
  Container,
  ContentWrapper,
  Label,
  Loader,
  LoaderWrapper
} from './button.styles'

// Components
import { ConditionallyRender } from 'components/utilities/conditionally-render'

// Utils
import { ternary } from 'utils/ternary'

export const Button: FunctionComponent<ButtonProps> = ({
  isLoading,
  isDisabled,
  isFullyAdaptative,
  borderType = 'large',
  variant = 'primary',
  label,
  iconElement,
  handleClick,
  ...buttonDefaultProps
}) => {
  const backgroundColor = ternary([
    [variant === 'primary', '#C11636'],
    [variant === 'secondary', '#FDF4F5'],
    [variant === 'white', '#fff'],
    [variant === 'info', '#F7FAFC']
  ]) as string
  const textColor = ternary([
    [variant === 'primary', '#fff'],
    [variant === 'secondary', '#DF1D3D'],
    [variant === 'white', '#C11636'],
    [variant === 'info', '#53A4C6'],
  ]) as string
  const borderColor = ternary([
    [variant === 'secondary', '#C11636'],
    [variant === 'info', '#53A4C6']
  ]) as string
  const buttonSize = ternary([
    [!label && !!iconElement, 'small'],
    [!!label, 'large']
  ]) as 'large' | 'small'

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Container
      className="pdg-button"
      size={buttonSize}
      backgroundColor={backgroundColor}
      isFullyAdaptative={!!isFullyAdaptative}
      disabled={isDisabled || isLoading}
      borderType={borderType}
      borderColor={borderColor}
      hasCursorPointer={!!handleClick || buttonDefaultProps.type === 'submit'}
      {...(!isDisabled &&
        !isLoading && { onClick: (ev) => !!handleClick && handleClick(ev) })}
      {...buttonDefaultProps}
    >
      <ConditionallyRender
        shouldRender={!isLoading}
        content={
          <ContentWrapper>
            <ConditionallyRender
              shouldRender={!!iconElement}
              content={iconElement}
            />

            <ConditionallyRender
              shouldRender={!!label}
              content={
                <Label
                  textColor={textColor}
                >
                  {label}
                </Label>
              }
            />
          </ContentWrapper>
        }
      />

      <ConditionallyRender
        shouldRender={!!isLoading}
        content={
          <LoaderWrapper>
            <Loader color={textColor}>
              <div>
                <div />
                <div />
                <div />
                <div />
              </div>
            </Loader>
          </LoaderWrapper>
        }
      />
    </Container>
  )
}
