// Icons
import { ReactComponent as arrowLeft } from './assets/arrow-left'
import { ReactComponent as chat } from './assets/chat'
import { ReactComponent as close } from './assets/close'
import { ReactComponent as chevronUp } from './assets/chevron-up'
import { ReactComponent as chevronDown } from './assets/chevron-down'
import { ReactComponent as warningOctagon } from './assets/warning-octagon'
import { ReactComponent as menu } from './assets/menu'
import { ReactComponent as warningCircle } from './assets/warning-circle'
import { ReactComponent as signOut } from './assets/sign-out'
import { ReactComponent as eye } from './assets/eye'
import { ReactComponent as check } from './assets/check'
import { ReactComponent as doubleCheck } from './assets/double-check'
import { ReactComponent as clock } from './assets/clock'
import { ReactComponent as youtube } from './assets/youtube'
import { ReactComponent as spotify } from './assets/spotify'
import { ReactComponent as tiktok } from './assets/tiktok'
import { ReactComponent as facebook } from './assets/facebook'
import { ReactComponent as instagram } from './assets/instagram'
import { ReactComponent as twitter } from './assets/twitter'
import { ReactComponent as sendChatFill } from './assets/send-chat-fill'
import { ReactComponent as copy } from './assets/copy'
import { ReactComponent as user } from './assets/user'
import { ReactComponent as key } from './assets/key'
import { ReactComponent as person } from './assets/person'

export const icons = {
  arrowLeft: { source: arrowLeft },
  chat: { source: chat },
  close: { source: close },
  chevronUp: { source: chevronUp },
  chevronDown: { source: chevronDown },
  warningOctagon: { source: warningOctagon },
  menu: { source: menu },
  warningCircle: { source: warningCircle },
  signOut: { source: signOut },
  eye: { source: eye },
  check: { source: check },
  doubleCheck: { source: doubleCheck },
  clock: { source: clock },
  youtube: { source: youtube },
  spotify: { source: spotify },
  tiktok: { source: tiktok },
  facebook: { source: facebook },
  instagram: { source: instagram },
  twitter: { source: twitter },
  sendChatFill: { source: sendChatFill },
  copy: { source: copy },
  user: { source: user },
  key: { source: key },
  person: { source: person }
}
