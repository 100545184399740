import styled from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

export const StaticContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 12px 16px;
  background: #c11636;

  ${MediaQueries.medium} {
    border-radius: 16px 16px 0 0;
  }
`

export const ResultsLabel = styled.p`
  color: #fdf4f5;
  font-family: 'Source Sans 3';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`

export const TotalHitsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const Label = styled.p`
  color: #fdf4f5;
  text-align: right;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`

export const Container = styled.div`
  width: 100%;

`

export const OpenedContent = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  background: #df1d3d;

  ${MediaQueries.medium} {
    border-radius: 0 0 16px 16px;
  }
`

export const ResultsCompositionWrapper = styled.div`
  width: 100%;
  height: 70px;

  display: flex;
  gap: 24px;
  align-items: center;

  ${MediaQueries.amedium} {
    flex-direction: column;
    height: auto;
  }
`

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  align-self: stretch;
  background: #e7536b;
`

export const SimulateSisuWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;

  ${MediaQueries.medium} {
    gap: 24px;
  }

  ${MediaQueries.amedium} {
    align-items: flex-start;
    gap: 16px;
    flex-direction: column;
  }
`

export const SimulateSisuTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;

  ${MediaQueries.medium} {
    gap: 8px;
  }
`

export const SimulateSisuTitle = styled.p`
  color: #fdf4f5;
  font-family: 'Source Sans 3';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

export const SimulateSisuDescription = styled.p`
  color: #f6bec7;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`

export const ResultsCardItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
`

export const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SubLabel = styled.p`
  color: #f6bec7;
  font-family: 'Source Sans 3';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`
