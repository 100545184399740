import styled, { css } from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${MediaQueries.medium} {
    width: fit-content;
    background: #f0f1f7;
    flex-direction: column;
    border-radius: 14px;
  }

  ${MediaQueries.amedium} {
    flex-direction: row;
    width: 100%;
  }
`

export const DayLabel = styled.p`
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
`

export const DayValue = styled.p`
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  white-space: nowrap;
`

type TabItemContainerStyleProps = {
  isActive?: boolean
}

export const TabItemContainer = styled.div<TabItemContainerStyleProps>`
  display: flex;
  width: 100%;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  ${MediaQueries.medium} {
    width: fit-content;
    flex-direction: column;
    gap: 0px;
    border-radius: 14px;
    padding: 42px 12px;
  }

  ${MediaQueries.amedium} {
    flex-direction: row;
    padding: 12px;
    width: 100%;
    gap: 8px;
  }

  ${({ isActive }) =>
    !!isActive &&
    css`
      background: #df1d3d;

      ${DayLabel} {
        color: #fdf4f5;
      }

      ${DayValue} {
        color: #fdf4f5;
      }
    `}

  ${({ isActive }) =>
    !isActive &&
    css`
      background: #f0f1f7;

      ${DayLabel} {
        color: #7c8195;
      }

      ${DayValue} {
        color: #7c8195;
      }
    `}
`
