// Dependencies
import React, { FunctionComponent } from 'react'
import {  Wrapper } from './label.styles'
import { Icon } from 'component-library/icon/icon.component'

export const Label: FunctionComponent<any> = ({
  label,
  icon,
  onLabelClick
}) => {
  return (
      <Wrapper  onClick={() => onLabelClick()}>
        {( icon) && <Icon name={icon} size={16} color='#666B7B'  />}
        <p className="label">{label}</p>
      </Wrapper>
  )
}
