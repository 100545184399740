// Dependencies
import styled, { css } from 'styled-components'

export const AuthCardSelectorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const AuthCardSelectorContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: 16px;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`

type AuthCardSelectorItemContainerStyleProps = {
  isActive: boolean
  hasAction: boolean
}

export const AuthCardSelectorItemContainer = styled.div<AuthCardSelectorItemContainerStyleProps>`
  display: flex;
  padding: 16px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #df1d3d;

  ${({ isActive }) =>
    !!isActive &&
    css`
      background: #df1d3d;
    `}

  ${({ isActive }) =>
    !isActive &&
    css`
      transition: all ease-in-out 0.2s;

      &:hover {
        background-color: #c11636;
      }
    `}

  ${({ hasAction }) =>
    hasAction &&
    css`
      cursor: pointer;
    `}
`

export const AuthCardSelectorItemContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media (min-width: 768px) {
    align-items: center;
  }
`

export const AuthCardSelectorTitleIcon = styled.img`
  width: 54px;
  height: 54px;
  flex-shrink: 0;
`

export const AuthCardSelectorTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: calc(100% - 32px);
`

export const AuthCardSelectorContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
`

export const AuthCardSelectorItemTitle = styled.p`
  color: #f0f1f7;
  font-family: 'Nunito';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
`

export const AuthCardSelectorItemDescription = styled.p`
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #fff;
`

export const ActiveIconWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;

  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #28b583;
  border-radius: 320px;
`
