// Dependencies
import styled, { css } from 'styled-components'

// Types
import { LinkProps } from './link.types'

export const Container = styled.a`
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: relative;
  transition: all 0.3s;
  opacity: 1;

  flex-shrink: 0;

  cursor: pointer;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
`

type LabelStyleProps = {
  variant: LinkProps['variant']
}

export const Label = styled.p<LabelStyleProps>`
  ${({ variant }) =>
    variant === 'password' &&
    css`
      font-family: 'Nunito';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      border-bottom: 1px solid #3e68d2;
      color: #3e68d2;
    `}

  ${({ variant }) =>
    variant === 'footer' &&
    css`
      font-family: 'Source Sans 3';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: #666b7b;
    `}

  ${({ variant }) =>
    variant === 'logout' &&
    css`
      font-family: 'Source Sans Pro';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
      color: #df1d3d;
      border-bottom: 1px solid #df1d3d;
    `}

  ${({ variant }) =>
    variant === 'term' &&
    css`
      color: #83899e;
      text-align: center;
      font-family: 'Nunito Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    `}
`
