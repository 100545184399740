// Services
import { ternary } from 'utils/ternary'
import CalculateGradesENEM from '../../services/TRIService'

// Utils
import { data } from './gabaritos.data'
import { EditionDaysUnion, ISubject, ITRINote } from './gabaritos.types'

interface IHits {
  subjectId: number
  mark: number
}

interface IResult {
  total: number
  hits: number
}

interface ITRIResult {
  max: number
  min: number
}

// =====

interface ISubjectResult {
  label: string
  result: IResult
  tri?: ITRIResult
}

interface ITotalResult {
  totalQuestions: number
  totalHits: number
  dayLabel: string
}

interface IDayResultsArgs {
  subjectResults: ISubjectResult[]
  totalResults: ITotalResult
}

export const getFilteredIrtsbySubject = (irt: any, subjectId: number) =>
  irt.push
    ? irt.filter((irtData: any) => !!irtData && irtData.subjectId === subjectId)
    : Object.values(irt)
        .map((prop: any) => prop)
        .filter((irtData: any) => !!irtData && irtData.subjectId === subjectId)

export const getMarks = (
  dayAnswers: string,
  studentAnswers: string
): Array<1 | 0> => {
  if (dayAnswers.length !== studentAnswers.length) {
    throw new Error('Gabarito em não conformidade')
  }

  const [dayArray, studentArray] = [
    dayAnswers.split(''),
    studentAnswers.split('')
  ]

  const marks = dayArray.map((day, index) => {
    if (
      data.alternatives.indexOf(day) > -1 &&
      data.alternatives.indexOf(studentArray[index]) > -1
    ) {
      return day === studentArray[index] ? 1 : 0
    }

    if (day === 'X') {
      return 1
    }

    return 0
  })

  return marks
}

export const getResultsCardName = (
  selectedEditionDayLabel: string,
  index: number
): string => {
  return ternary([
    [index === 0 && selectedEditionDayLabel === 'dia-1', 'Linguagens'],
    [index === 0 && selectedEditionDayLabel === 'dia-2', 'Natureza'],
    [index === 1 && selectedEditionDayLabel === 'dia-1', 'Humanas'],
    [index === 1 && selectedEditionDayLabel === 'dia-2', 'Matemática']
  ]) as string
}

export const getResultsTotalHits = (results?: ITotalResult): string => {
  return ternary([
    [
      !!results,
      `Total: ${results?.totalHits ?? 0}/${results?.totalQuestions ?? 0}`
    ],
    [!results, 'Total: 0/90']
  ]) as string
}

export const getGrade = (
  dayAnswers: string,
  studentAnswers: string,
  subjects: string,
  subjectId: number
): IHits[] => {
  let dayAnswersTmp = ''
  let studentAnswersTmp = ''
  let subjectsTmp = ''

  dayAnswers.split('').map((item, index) => {
    if (Number(subjects[index]) === subjectId) {
      studentAnswersTmp = studentAnswersTmp.concat(studentAnswers[index])
      dayAnswersTmp = dayAnswersTmp.concat(dayAnswers[index])
      subjectsTmp = subjectsTmp.concat(subjects[index])
    }

    return item
  })

  const marks = getMarks(dayAnswersTmp, studentAnswersTmp)

  const marksPerSubjects = subjectsTmp.split('').map((subjectId, index) => ({
    subjectId: +subjectId,
    mark: marks[index]
  }))

  return marksPerSubjects
}

export const getQuestionsColumnsLength = (windowWidth: number): number => {
  return ternary([
    [windowWidth < 1024, 2],
    [windowWidth < 768, 1],
    [true, 3]
  ]) as number
}

export const getFormattedTitle = (userNameStorage: string | null): string => {
  return ternary([
    [!!userNameStorage, `Olá, ${userNameStorage?.split(' ')[0]}`],
    [!userNameStorage, 'Olá,']
  ]) as string
}

export const getQuestionNumber = (number: number): string => {
  let questionLabel: string = `${number}`

  if (number < 10) {
    questionLabel = `00${number}`
  }

  if (number >= 10 && number < 100) {
    questionLabel = `0${number}`
  }

  return questionLabel
}

export const getResults = (
  selectedEditionDayLabel: EditionDaysUnion,
  editionDayResponses: string,
  editionDayUserResponses: string,
  editionDayResponsesSubjects: string,
  editionSubjects: ISubject[],
  TRINotes: ITRINote[] | null,
  editionDayResponsesIRT: any | null
) => {
  const dayLabelTmp = selectedEditionDayLabel.split('-')

  const dayLabel = dayLabelTmp.join(' ')

  const captalizedDayLabel = dayLabel[0].toUpperCase() + dayLabel.substr(1)

  let dayResultsTmp: IDayResultsArgs = {
    subjectResults: [],
    totalResults: {
      totalQuestions: 90,
      totalHits: 0,
      dayLabel: captalizedDayLabel
    }
  }

  let subjects: { id: number; name: string }[] = []

  editionDayResponses.split('').map((correctResponse, index) => {
    const userResponse = editionDayUserResponses[index]

    const subjectId = editionDayResponsesSubjects[index]

    const subject = editionSubjects.find(
      (subject) => `${subject.id}` === subjectId
    ) as ISubject

    let subjectResultsTmp: ISubjectResult[] = dayResultsTmp.subjectResults

    const hasSubjectInSubjectResults = subjectResultsTmp.find(
      (subjectResult) => subjectResult.label === subject?.name
    )

    if (!hasSubjectInSubjectResults) {
      subjects = [...subjects, { id: subject?.id, name: subject?.name }]

      const newSubjectResult = [
        ...subjectResultsTmp,
        {
          label: subject?.name,
          result: {
            total: 45,
            hits: 0
          }
        }
      ]

      subjectResultsTmp = newSubjectResult

      dayResultsTmp.subjectResults = newSubjectResult
    }

    if (
      data.alternatives.indexOf(userResponse) > -1 &&
      data.alternatives.indexOf(correctResponse) > -1
    ) {
      const isCorrect = correctResponse === userResponse

      subjectResultsTmp = subjectResultsTmp.map((subjectResult) => {
        if (subjectResult.label === subject.name) {
          return {
            ...subjectResult,
            result: {
              total: 45,
              hits: isCorrect
                ? subjectResult.result.hits + 1
                : subjectResult.result.hits
            }
          }
        } else {
          return subjectResult
        }
      })

      const newDayResultsTmp = {
        subjectResults: subjectResultsTmp,
        totalResults: {
          ...dayResultsTmp.totalResults,
          totalQuestions: 90,
          totalHits: isCorrect
            ? dayResultsTmp.totalResults.totalHits + 1
            : dayResultsTmp.totalResults.totalHits
        }
      }

      dayResultsTmp = newDayResultsTmp
    }

    return correctResponse
  })

  let TRINotesTmp = TRINotes

  if (!!editionDayResponsesIRT) {
    subjects.map((subject, index) => {
      const [formmatedIrt] = getFilteredIrtsbySubject(
        editionDayResponsesIRT,
        subject.id
      )

      const allGrades = getGrade(
        editionDayResponses,
        editionDayUserResponses,
        editionDayResponsesSubjects,
        subject.id
      )

      const hits = allGrades
        .filter((grade) => grade.subjectId === subject.id)
        .map((grade) => grade.mark)

      if (
        hits?.length > 0 &&
        !!formmatedIrt &&
        formmatedIrt.values?.length > 0 &&
        hits?.length === formmatedIrt.values?.length
      ) {
        const subjectIrt = CalculateGradesENEM(
          hits,
          formmatedIrt.values,
          subject.id
        )

        dayResultsTmp.subjectResults[index].tri = {
          min: Math.round(subjectIrt * 0.95),
          max: Math.round(subjectIrt * 1.05)
        }

        if (!!TRINotes && !!TRINotesTmp) {
          TRINotesTmp = TRINotesTmp.map((TRINote) => {
            if (TRINote.subjectId === subject.id) {
              return { ...TRINote, note: Math.round(subjectIrt) }
            } else {
              return TRINote
            }
          })
        }
      }
    })
  }

  return { TRINotesTmp, dayResultsTmp }
}
