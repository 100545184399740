import styled from 'styled-components'
import { MediaQueries } from '../../utils/breakpoints'

export const Wrapper = styled.main`
  position: relative;
  z-index: 9;

  main {
    margin: auto;
    //width: 100vw;
  }
`

export const ButtonsWrapper = styled.div`
  width: 300px;
  background-color: #fafafc;
  padding: 16px;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  div {
    display: flex;
    gap: 16px;
    flex-direction: column;

    ${MediaQueries.medium} {
      flex-direction: row;
    }

    gabarito-button {
      width: 128px;
      height: 32px;
    }
  }
`
