// Dependencies
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { addSeconds } from 'date-fns'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'

// Services
import { FirebaseService as Service } from '../../services/firebaseService'

// Utils
import { data } from './gabaritos.data'
import {
  Filters,
  EditionsUnion,
  EditionDaysUnion,
  EditionDayVersionItemsUnion,
  EditionDayVersionsUnion,
  ISubject,
  IUser,
  ITRINote,
  IEnemEditionData
} from './gabaritos.types'
import { getResults } from './gabaritos.helpers'

// Components
import {
  clearUserLocalStorage,
  getUserLocalStorage,
  setUserLocalStorage
} from 'utils/userLocalStorage'
import { SignUpFormData } from 'component-library/sign-up/sign-up.schema'
import { SignInFormData } from 'component-library/sign-in/sign-in.schema'
import { restAPI } from 'services/rest-api'
import { leadsApi } from 'services/leads-api'
import {
  cleanBrowserStorageData,
  fetchBrowserStorageData,
  saveBrowserStorageData
} from 'services/browser-storage'
import {
  TOKEN_STORAGE_KEY,
  TOKEN_USER_ID_STORAGE_KEY,
  TOKEN_VALID_TIME_STORAGE_KEY,
  USER_NAME_STORAGE_KEY
} from 'utils/constants'
import { useModalContext } from 'contexts/modal'

interface IResult {
  total: number
  hits: number
}

interface ITRIResult {
  max: number
  min: number
}

// =====

interface ISubjectResult {
  label: string
  result: IResult
  tri?: ITRIResult
}

interface ITotalResult {
  totalQuestions: number
  totalHits: number
  dayLabel: string
}

// =====

interface IDayResultsArgs {
  subjectResults: ISubjectResult[]
  totalResults: ITotalResult
}

// =====

export function useGabaritos() {
  const { isOpened, setIsOpened, setIsUserLoggedIn } = useModalContext()

  const [filters, setFilters] = useState<Filters | null>(null)

  const [selectedEnemEdition, setSelectedEnemEdition] =
    useState<EditionsUnion | null>(null)
  const [selectedEnemEditionData, setSelectedEnemEditionData] = useState<
    IEnemEditionData[] | null
  >(null)

  const [editionSubjects, setEditionSubjects] = useState<ISubject[] | null>(
    null
  )
  const [selectedEditionDayIndex, setSelectedEditionDayIndex] =
    useState<number>(0)
  const [selectedEditionDayLabel, setSelectedEditionDayLabel] =
    useState<EditionDaysUnion | null>(null)

  const [selectedLanguage, setSelectedLanguage] =
    useState<EditionDayVersionItemsUnion | null>(null)
  const [selectedColor, setSelectedColor] =
    useState<EditionDayVersionsUnion | null>(null)

  const [editionDayResponses, setEditionDayResponses] = useState<string>(
    data.defaultResponses
  )
  const [editionDayResponsesSubjects, setEditionDayResponsesSubjects] =
    useState<string | null>(null)
  const [editionDayResponsesStart, setEditionDayResponsesStart] =
    useState<number>(1)
  const [editionDayResponsesIRT, setEditionDayResponsesIRT] = useState<
    any | null
  >(null)
  const [editionDayUserResponses, setEditionDayUserResponses] =
    useState<string>(data.defaultUserAlternatives)
  const [editionDayUserHits, setEditionDayUserHits] = useState<string>(
    data.defaultUserAlternatives
  )

  const [dayResults, setDayResults] = useState<IDayResultsArgs | null>(null)

  const [userData, setUserData] = useState<IUser | null>(null)

  const [TRINotes, setTRINotes] = useState<ITRINote[] | null>(null)

  const [userAuthMethod, setUserAuthMethod] = useState<
    'login' | 'register' | null
  >(null)
  const [currentStepAuthForm, setCurrentStepAuthForm] = useState<
    'choose' | 'login' | 'register'
  >('choose')

  useEffect(() => {
    if (!!userData) {
      setIsUserLoggedIn(true)
    }
  }, [userData])

  useEffect(() => {
    setAuthData({
      isLoading: false,
      errorMessage: undefined
    })
  }, [currentStepAuthForm])

  useEffect(() => {
    if (!isOpened) {
      setUserAuthMethod(null)
      setCurrentStepAuthForm('choose')
    }
  }, [isOpened])

  const userIdValue = fetchBrowserStorageData({
    key: TOKEN_USER_ID_STORAGE_KEY
  })

  useEffect(() => {
    if (!userIdValue && !!userData) {
      handleSignOut()
    }
  }, [userIdValue])

  const [hasVisibleCorrection, setHasVisibleCorrection] =
    useState<boolean>(true)

  useEffect(() => {
    Service.init()
  }, [])

  useEffect(() => {
    ;(async () => {
      const localStorage = getUserLocalStorage()

      if (!!localStorage?.user) {
        setUserData(localStorage.user)
      }

      const filtersResponse = await Service.get('filters')

      filtersResponse.years.reverse()

      setFilters(filtersResponse)

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      const selectEdition = params.get("enem");

      if (selectEdition) {
        setSelectedEnemEdition(selectEdition as EditionsUnion)
      } else {
        setSelectedEnemEdition(filtersResponse.years[0].key as EditionsUnion)
      }
    })()
  }, [])

  useEffect(() => {
    if (!!selectedEnemEdition) {
      // TODO: alternar para exibir o primeiro dia como padrão - INÍCIO

      // setSelectedEditionDayIndex(0)

      // setSelectedEditionDayLabel(filters?.days[0].key as EditionDaysUnion)

      setSelectedEditionDayIndex(selectedEnemEdition === '2024' ? 1 : 0)

      setSelectedEditionDayLabel(
        selectedEnemEdition === '2024'
          ? 'dia-2'
          : (filters?.days[0].key as EditionDaysUnion)
      )

      // TODO: alternar para exibir o primeiro dia como padrão - FIM

      ;(async () => {
        const url = `gabarito/exams/enem/editions/${selectedEnemEdition}`

        const editionFirstDayNameResponse = await Service.get(
          `${url}/days/dia-1/name`
        )

        const editionSecondDayNameResponse = await Service.get(
          `${url}/days/dia-2/name`
        )

        const editionFirstDayVideoUrlResponse = await Service.get(
          `${url}/days/dia-1/videoUrl`
        )

        const editionSecondDayVideoUrlResponse = await Service.get(
          `${url}/days/dia-2/videoUrl`
        )

        setSelectedEnemEditionData([
          {
            name: editionFirstDayNameResponse,
            videoUrl: editionFirstDayVideoUrlResponse
          },
          {
            name: editionSecondDayNameResponse,
            videoUrl: editionSecondDayVideoUrlResponse
          }
        ])
      })()
    }
  }, [selectedEnemEdition])

  useEffect(() => {
    setSelectedColor(null)

    setSelectedLanguage(null)

    setEditionDayResponses(data.defaultResponses)

    setEditionDayUserHits(data.defaultUserAlternatives)

    setEditionDayUserResponses(data.defaultUserAlternatives)

    setEditionDayResponsesIRT(null)

    setDayResults(null)
  }, [selectedEnemEdition, selectedEditionDayLabel])

  useEffect(() => {
    if (!!selectedEnemEdition) {
      ;(async () => {
        const editionSubjectsResponse = await Service.get(
          `gabarito/exams/enem/editions/${selectedEnemEdition}/subjects`
        )

        setEditionSubjects(editionSubjectsResponse)
      })()

      if (!!userData && !!selectedEditionDayLabel) {
        ;(async () => {
          // const userDataResponse = await Service.get(
          //   `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userData.email.replaceAll(
          //     '.',
          //     ''
          //   )}`
          // )

          const userDataResponse = await Service.get(
            `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userIdValue}`
          )

          if (selectedEditionDayLabel === 'dia-1') {
            if (!!userDataResponse?.version) {
              setSelectedLanguage(userDataResponse.version)
            } else {
              // Service.update(
              //   `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userData.email.replaceAll(
              //     '.',
              //     ''
              //   )}`,
              //   { version: 'ingles' }
              // )
              Service.update(
                `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userIdValue}`,
                { version: 'ingles' }
              )

              setSelectedLanguage('ingles')
            }
          }

          if (!!userDataResponse?.item) {
            setSelectedColor(userDataResponse.item)
          } else {
            // Service.update(
            //   `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userData.email.replaceAll(
            //     '.',
            //     ''
            //   )}`,
            //   { item: 'amarela' }
            // )
            Service.update(
              `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userIdValue}`,
              { item: 'amarela' }
            )

            setSelectedColor('amarela')
          }

          if (!!userDataResponse?.studentAnswerKeys) {
            setEditionDayUserResponses(userDataResponse.studentAnswerKeys)
          } else {
            setEditionDayUserResponses(data.defaultUserAlternatives)
          }

          if (!!userDataResponse?.acertos) {
            setEditionDayUserHits(userDataResponse.acertos)
          } else {
            setEditionDayUserHits(data.defaultUserAlternatives)
          }
        })()
      }
    }
  }, [selectedEnemEdition, selectedEditionDayLabel, userData])

  const setDefaultTRINotes = () => {
    if (!!editionSubjects) {
      const TRINotes = editionSubjects.map((subject: ISubject) => ({
        subjectId: subject.id,
        subjectSlug: subject.slug,
        note: null
      }))

      setTRINotes(TRINotes)
    }
  }

  useEffect(() => {
    setDefaultTRINotes()
  }, [selectedEnemEdition, userData])

  useEffect(() => {
    if (!TRINotes) {
      setDefaultTRINotes()
    }
  }, [editionSubjects])

  useEffect(() => {
    if (
      (!!selectedLanguage || selectedEditionDayLabel === 'dia-2') &&
      !!selectedColor &&
      !!selectedEnemEdition &&
      !!selectedEditionDayLabel
    ) {
      ;(async () => {
        const url = `gabarito/exams/enem/editions/${selectedEnemEdition}/days/${selectedEditionDayLabel}/versions/${selectedColor}/items/${
          selectedEditionDayLabel === 'dia-2' ? 'geral' : selectedLanguage
        }`

        const dayResponse = await Service.get(url)

        if (!!dayResponse) {
          setEditionDayResponses(dayResponse.answerKey)

          setEditionDayResponsesSubjects(dayResponse.subjects)

          setEditionDayResponsesStart(dayResponse.start)

          if (!!dayResponse?.irt) {
            setEditionDayResponsesIRT(dayResponse.irt)
          }

          Service.onChange(`${url}/answerKey`, (value) => {
            setEditionDayResponses(value)
          })

          Service.onChange(`${url}/irt`, (value) => {
            if (!!value) {
              setEditionDayResponsesIRT(value)
            }
          })
        }
      })()
    }
  }, [selectedLanguage, selectedColor])

  useEffect(() => {
    if (
      !!selectedEnemEdition &&
      !!userData &&
      !!selectedEditionDayLabel &&
      !!selectedColor &&
      !!editionDayUserResponses &&
      !!editionDayResponsesSubjects &&
      (selectedEditionDayLabel === 'dia-2' || !!selectedLanguage) &&
      !!editionDayUserHits
    ) {
      // const acertosUrl = `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userData.email.replaceAll(
      //   '.',
      //   ''
      // )}`
      const acertosUrl = `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userIdValue}`

      let respostasUrl = `gabarito/respostas/enem/editions/${selectedEnemEdition}/days/${selectedEditionDayLabel}/versions/${selectedColor}/items/`

      if (selectedEditionDayLabel === 'dia-1') {
        respostasUrl = respostasUrl.concat(`${selectedLanguage}`)
      } else {
        respostasUrl = respostasUrl.concat(`geral`)
      }

      const newResults = {
        studentAnswerKeys: editionDayUserResponses,
        subjects: editionDayResponsesSubjects,
        acertos: editionDayUserHits
      }

      Service.update(acertosUrl, newResults)
      Service.update(respostasUrl, {
        [`${userIdValue}`]: editionDayUserResponses.replaceAll('?', ' ')
      })
    }
  }, [
    editionDayUserResponses,
    editionDayUserHits,
    selectedColor,
    selectedLanguage
  ])

  useEffect(() => {
    if (
      !!selectedEditionDayLabel &&
      !!editionDayResponses &&
      !!editionDayResponsesSubjects &&
      !!editionSubjects &&
      (!!selectedLanguage || selectedEditionDayLabel === 'dia-2') &&
      !!selectedColor
    ) {
      const { TRINotesTmp, dayResultsTmp } = getResults(
        selectedEditionDayLabel,
        editionDayResponses,
        editionDayUserResponses,
        editionDayResponsesSubjects,
        editionSubjects,
        TRINotes,
        editionDayResponsesIRT
      )

      setTRINotes(TRINotesTmp)

      setDayResults(dayResultsTmp)
    } else {
      setDayResults(null)
    }
  }, [
    editionDayResponses,
    editionDayResponsesSubjects,
    editionDayUserResponses,
    editionSubjects,
    selectedColor,
    selectedEditionDayLabel,
    selectedLanguage,
    editionDayResponsesIRT
  ])

  useEffect(() => {
    if (!!userData && !!selectedEnemEdition && !!selectedEditionDayLabel) {
      let version: string | null = null
      let item: string | null = null
      let studentAnswerKeys: string | null = null

      let dayResponse: any | null = null

      ;(async () => {
        // const userDataResponse = await Service.get(
        //   `gabarito/acertos/enem/${selectedEnemEdition}/${
        //     selectedEditionDayLabel === 'dia-2' ? 'dia-1' : 'dia-2'
        //   }/${userData.email.replaceAll('.', '')}`
        // )
        const userDataResponse = await Service.get(
          `gabarito/acertos/enem/${selectedEnemEdition}/${
            selectedEditionDayLabel === 'dia-2' ? 'dia-1' : 'dia-2'
          }/${userIdValue}`
        )

        if (!!userDataResponse?.version) {
          version = userDataResponse.version
        }

        if (!!userDataResponse?.item) {
          item = userDataResponse.item
        }

        if (!!userDataResponse?.studentAnswerKeys) {
          studentAnswerKeys = userDataResponse.studentAnswerKeys
        }

        if (!!item && (!!version || selectedEditionDayLabel === 'dia-1')) {
          const url = `gabarito/exams/enem/editions/${selectedEnemEdition}/days/${
            selectedEditionDayLabel === 'dia-2' ? 'dia-1' : 'dia-2'
          }/versions/${item}/items/${
            selectedEditionDayLabel === 'dia-2' ? version : 'geral'
          }`

          dayResponse = await Service.get(url)
        }

        if (
          !!selectedEditionDayLabel &&
          !!editionSubjects &&
          !!dayResponse &&
          !!dayResponse?.answerKey &&
          !!dayResponse?.subjects &&
          !!studentAnswerKeys &&
          !!item &&
          (!!version || selectedEditionDayLabel === 'dia-1')
        ) {
          const { TRINotesTmp, dayResultsTmp } = getResults(
            selectedEditionDayLabel,
            dayResponse.answerKey,
            studentAnswerKeys,
            dayResponse.subjects,
            editionSubjects,
            TRINotes,
            dayResponse?.irt || null
          )

          setTRINotes(TRINotesTmp)
        }
      })()
    }
  }, [selectedEditionDayLabel, selectedEnemEdition, userData, dayResults])

  const colorOptions = useMemo(() => {
    const formattedEnemEdition = Number(selectedEnemEdition);

    const shouldUseNewColors = !!formattedEnemEdition && typeof formattedEnemEdition === 'number' && formattedEnemEdition >= 2024;

    const colors = shouldUseNewColors ? data.testColors : data.oldTestColors;

    const colorToRemove = selectedEditionDayLabel === 'dia-1' ? 'cinza' : 'branca';

    return colors.filter((color) => color.key !== colorToRemove);
  }, [selectedEnemEdition, selectedEditionDayLabel])

  const selectSelectedColor = useMemo(() => {
    if (!!selectedEditionDayLabel) {
      const selectedColorTmp = colorOptions.find((testColors) => {
        return testColors.key === selectedColor
      })

      return selectedColorTmp
    }

    return undefined
  }, [selectedColor, selectedEditionDayLabel, colorOptions])

  const selectSelectedLanguage = useMemo(() => {
    if (!!selectedEditionDayLabel) {
      const selectedLanguageTmp = data.testLanguages.find(
        (testLanguages) => testLanguages.key === selectedLanguage
      )

      return selectedLanguageTmp
    }

    return undefined
  }, [selectedLanguage, selectedEditionDayLabel])

  const handleOpeningSisuSite = () => {
    let url = 'https://sisu.proenem.com.br'

    if (!!TRINotes) {
      TRINotes.map((TRINote) => {
        if (!!TRINote.note) {
          const subjectNote = `${TRINote.subjectSlug}=${TRINote.note}`

          if (!url.includes('?')) {
            url = url.concat(`?${subjectNote}`)
          } else {
            url = url.concat(`&${subjectNote}`)
          }
        }
      })
    }

    window.open(url, '_blank')
  }

  const handleSelectEnemEdition = (edition: string) => {
    window.scrollTo(0, 0)

    setSelectedEnemEdition(edition as EditionsUnion)
    setHasVisibleCorrection(true)
  }

  const handleSelectTab = (editionDay: EditionDaysUnion, index: number) => {
    const editionDayResponsesStartTmp = editionDay === 'dia-1' ? 1 : 91

    setEditionDayResponsesStart(editionDayResponsesStartTmp)

    setSelectedEditionDayIndex(index)

    setSelectedEditionDayLabel(editionDay)

    setHasVisibleCorrection(true)
  }

  const handleSelectColor = (event: any) => {
    if (!!userData) {
      // Service.update(
      //   `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userData.email.replaceAll(
      //     '.',
      //     ''
      //   )}`,
      //   { item: event.key }
      // )
      Service.update(
        `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userIdValue}`,
        { item: event.key }
      )

      setSelectedColor(event.key as EditionDayVersionsUnion)
    } else {
      handleModalTrigger()
    }
  }

  const handleSelectLanguage = (event: any) => {
    if (!!userData) {
      // Service.update(
      //   `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userData.email.replaceAll(
      //     '.',
      //     ''
      //   )}`,
      //   { version: event.detail }
      // )
      Service.update(
        `gabarito/acertos/enem/${selectedEnemEdition}/${selectedEditionDayLabel}/${userIdValue}`,
        { version: event.key }
      )

      setSelectedLanguage(event.key as EditionDayVersionItemsUnion)
    } else {
      handleModalTrigger()
    }
  }

  const handleCopyToClipboard = () => {
    if (
      !!editionDayResponses &&
      !!selectedEnemEdition &&
      !!selectedEditionDayLabel &&
      !!editionDayResponsesStart &&
      !!selectedColor &&
      (!!selectedLanguage || selectedEditionDayLabel === 'dia-2')
    ) {
      const editedAnswers = editionDayResponses
        .split('')
        .map((response, index) => {
          return `${editionDayResponsesStart + index}-${response}, `
        })

      const copyWithLanguage = `ENEM ${selectedEnemEdition} - ${selectedEditionDayLabel} - ${selectedColor} - ${selectedLanguage} : ${editedAnswers.join(
        ''
      )}`

      const copy = `ENEM ${selectedEnemEdition} - ${selectedEditionDayLabel} - ${selectedColor} : ${editedAnswers.join(
        ''
      )}`

      const textField = document.createElement('textarea')

      textField.innerText = !!selectedLanguage ? copyWithLanguage : copy

      document.body.appendChild(textField)

      textField.select()

      document.execCommand('copy')

      textField.remove()

      alert('Gabarito copiado para a área de transferência!')
    }
  }

  const hasUnansweredEditionDayQuestions = useMemo(() => {
    if (!!editionDayResponses && !!editionDayResponses.length) {
      return !!editionDayResponses
        .split('')
        .find((response) => response === '?')
    }

    return true
  }, [editionDayResponses])

  const handleOpeningWhatsapp = () => {
    window.open(
      'https://api.whatsapp.com/send/?phone=5521995481656&text&app_absent=0',
      '_blank'
    )
  }

  const setCookie = (token: string) => {
    // Para testar a função abaixo no localhost é preciso deixar as
    // flags domain e secure comentadas e o valor da flag sameSite precisar ser 'Lax'

    Cookies.set('authData', token, {
      domain: '.proenem.com.br',
      path: '/',
      secure: true,
      sameSite: 'None'
    })
  }

  const removeCookie = () => {
    Cookies.remove('authData', {
      domain: '.proenem.com.br',
      path: '/'
    })
  }

  const createLeadsTag = async () => {
    try {
      await leadsApi.post(`/me/leads`, {
        tag: `${process.env.REACT_LEADS_TAG_VALUE}`
      })

      return true
    } catch (error: any) {
      return false
    }
  }

  const [authData, setAuthData] = useState<{
    isLoading?: boolean
    errorMessage?: string
  }>({})

  const handleSignInSubmit = useCallback(async (data: SignInFormData) => {
    try {
      const { email, password } = data.signIn

      setAuthData({
        isLoading: true,
        errorMessage: undefined
      })

      const response = await restAPI.post('/token', {
        email,
        password
      })

      try {
        createLeadsTag()
      } finally {
        console.log('Error creating leads tag')
      }

      const { token, credentials, expires_in } = response.data

      const formattedToken = `Bearer ${token}`

      const decodedToken: any = jwtDecode(token)
      const userId = decodedToken.person?.userId

      saveBrowserStorageData({
        key: TOKEN_USER_ID_STORAGE_KEY,
        value: userId
      })

      saveBrowserStorageData({
        key: TOKEN_STORAGE_KEY,
        value: formattedToken
      })

      saveBrowserStorageData({
        key: TOKEN_VALID_TIME_STORAGE_KEY,
        value: addSeconds(new Date(), expires_in).toISOString()
      })

      saveBrowserStorageData({
        key: USER_NAME_STORAGE_KEY,
        value: credentials?.name ?? ''
      })

      setUserLocalStorage({
        email,
        name: credentials?.name ?? ''
      })

      setUserData({
        email,
        name: credentials?.name ?? ''
      })

      setIsOpened(false)

      setAuthData({
        isLoading: false,
        errorMessage: undefined
      })

      setCookie(token)

      return true
    } catch (error: any) {
      setAuthData({
        isLoading: false,
        errorMessage:
          error?.response?.data?.detail ?? 'Ocorreu um erro na autenticação.'
      })

      return false
    }
  }, [])

  const handleSignUpSubmit = useCallback(
    async (data: SignUpFormData, recaptchaToken: string) => {
      try {
        const { name, email, phone, password } = data.signUp

        setAuthData({
          isLoading: true,
          errorMessage: undefined
        })

        const response = await restAPI.post(
          '/token/new',
          {
            name,
            email,
            phone,
            password
          },
          {
            headers: {
              'x-recaptcha': recaptchaToken
            }
          }
        )

        const { person } = response.data

        await handleSignInSubmit({ signIn: { email, password } })

        setUserLocalStorage({
          email,
          name: person?.name ?? ''
        })
        setUserData({
          email,
          name: person?.name ?? ''
        })
        setIsOpened(false)

        setAuthData({
          isLoading: false,
          errorMessage: undefined
        })

        return true
      } catch (error: any) {
        setAuthData({
          isLoading: false,
          errorMessage:
            error?.response?.data?.detail ?? 'Ocorreu um erro no cadastro.'
        })

        return false
      }
    },
    [handleSignInSubmit]
  )

  const handleModalTrigger = () => {
    const localStorageData = getUserLocalStorage()

    if (!localStorageData) {
      setIsOpened(true)
      return
    }

    const { user } = localStorageData

    if (Object.keys(user).length === 0) {
      setIsOpened(true)
    }
  }

  const handleSelectQuestion = (event: CustomEvent, index: number) => {
    if (!!selectedEditionDayLabel && !!userData) {
      const { selectedAlternative } = event.detail

      const editionDayResponsesTmp = editionDayResponses.split('')

      const editionDayUserHitsTmp = editionDayUserHits.split('')

      const editionDayUserResponsesTmp = editionDayUserResponses.split('')

      editionDayUserResponsesTmp[index] =
        selectedAlternative === editionDayUserResponsesTmp[index]
          ? '?'
          : selectedAlternative

      editionDayUserHitsTmp[index] =
        selectedAlternative === editionDayResponsesTmp[index] ? '1' : '0'

      setEditionDayUserHits(editionDayUserHitsTmp.join(''))

      setEditionDayUserResponses(editionDayUserResponsesTmp.join(''))
    } else {
      handleModalTrigger()
    }
  }

  useEffect(() => {
    const handleNavbarSignOut = () => {
      handleSignOut()
    }

    window.addEventListener('handleNavbarSignOut', handleNavbarSignOut)

    return () => {
      window.removeEventListener('handleNavbarSignOut', handleNavbarSignOut)
    }
  }, [])

  const handleSignOut = () => {
    clearUserLocalStorage()

    cleanBrowserStorageData({
      key: TOKEN_STORAGE_KEY
    })
    cleanBrowserStorageData({
      key: USER_NAME_STORAGE_KEY
    })
    cleanBrowserStorageData({
      key: TOKEN_VALID_TIME_STORAGE_KEY
    })
    cleanBrowserStorageData({
      key: TOKEN_USER_ID_STORAGE_KEY
    })

    setIsUserLoggedIn(false)

    setSelectedColor(null)

    setSelectedLanguage(null)

    setHasVisibleCorrection(true)

    setEditionDayUserResponses(data.defaultUserAlternatives)

    setDayResults(null)

    setUserData(null)

    removeCookie()
  }

  const getEditionLabel = useMemo(() => {
    if (!!filters && !!selectedEnemEdition) {
      const editionLabel = filters.years.find(
        (year: any) => year.key === selectedEnemEdition
      )

      return editionLabel?.value
    }
  }, [selectedEnemEdition])

  return {
    filters,
    selectedEnemEdition,
    selectedEnemEditionData,
    userData,
    handleSignOut,
    getEditionLabel,
    selectedEditionDayIndex,
    handleSelectTab,
    dayResults,
    colorOptions,
    selectSelectedColor,
    handleSelectColor,
    selectedEditionDayLabel,
    selectSelectedLanguage,
    handleSelectLanguage,
    editionDayResponses,
    selectedLanguage,
    selectedColor,
    editionDayUserResponses,
    editionDayResponsesStart,
    handleSelectQuestion,
    handleSelectEnemEdition,
    handleOpeningWhatsapp,
    handleCopyToClipboard,
    hasUnansweredEditionDayQuestions,
    handleOpeningSisuSite,
    hasVisibleCorrection,
    setHasVisibleCorrection,
    isOpened,
    setIsOpened,
    userAuthMethod,
    setUserAuthMethod,
    currentStepAuthForm,
    setCurrentStepAuthForm,
    handleSignInSubmit,
    authData,
    handleSignUpSubmit
  }
}
