// Dependencies
import * as zod from 'zod'

const signUpValidationSchema = zod
  .object({
    email: zod.string().trim().min(1, 'O e-mail é obrigatório'),
    password: zod
      .string()
      .trim()
      .min(5, 'A senha é obrigatória e deve conter 5 ou mais caracteres'),
    name: zod.string().trim().min(1, 'O nome é obrigatório'),
    phone: zod
      .string()
      .trim()
      .min(1, 'O celular é obrigatório')
      .refine((value) => {
        const phoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/
        return phoneRegex.test(value)
      }, 'Celular inválido'),
    confirmEmail: zod
      .string()
      .trim()
      .min(1, 'Confirmar o e-mail é obrigatório'),
    termsAccepted: zod.boolean().refine((val) => val === true, {
      message: 'Você deve aceitar os termos de uso'
    })
  })
  .refine((data) => data.email === data.confirmEmail, {
    message: 'Os e-mails estão diferentes',
    path: ['confirmEmail']
  })

export const signUpFormValidationSchema = zod.object({
  signUp: signUpValidationSchema
})

export type SignUpFormData = zod.infer<typeof signUpFormValidationSchema>
