// Dependencies
import styled, { css, keyframes } from 'styled-components';

const skeletonAnimation = (color: string) => keyframes`
  from {
    background: ${color};
  }

  to {
    background: ${color.concat('44')};
  }
`;

export type ContainerStyleProps = {
  height?: number;
  width?: number;
  borderRadius?: number;
  animationColor?: string;
};

export const Container = styled.span<ContainerStyleProps>`
  display: flex;
  flex-shrink: 0;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: ${props => (props.height ? `${props.height}px` : '100%')};
  background: #f1f1f7;
  animation: 0.5s alternate infinite;

  ${({ borderRadius }) =>
    !!borderRadius &&
    css`
      border-radius: ${`${borderRadius}px`};
    `}

  ${({ animationColor }) =>
    !!animationColor &&
    css`
      animation-name: ${skeletonAnimation(
        animationColor,
      )};
    `}

    ${({ animationColor }) =>
    !animationColor &&
    css`
      animation-name: ${skeletonAnimation('#f1f1f7')};
    `}
`;
