// Dependencies
import { css } from 'styled-components'
import { MediaQueries } from 'utils/breakpoints'

export const pageGutter = css`
  padding: 24px 0;
  width: 100%;
  margin: 0 auto;

  ${MediaQueries.medium} {
    max-width: 677px;
    padding: 32px 0px;
  }

  ${MediaQueries.amedium} {
    max-width: 972px;
  }

  ${MediaQueries.notebook} {
    max-width: 1226px;
  }

  ${MediaQueries.fullhd} {
    max-width: 1440px;
  }
`
