// Dependencies
import axios from 'axios'

// Services
import {
  cleanBrowserStorageData,
  fetchBrowserStorageData
} from './browser-storage'

// Utils
import { TOKEN_STORAGE_KEY } from '../utils/constants'

export const leadsApi = axios.create({
  baseURL: `${process.env.REACT_LEADS_API_URL}`
})

leadsApi.interceptors.request.use(
  (request) => {
    const applicationToken = fetchBrowserStorageData({
      key: TOKEN_STORAGE_KEY
    })

    if (applicationToken) {
      request.headers.Authorization = applicationToken
    }

    return request
  },
  (error) => Promise.reject(error)
)

leadsApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      cleanBrowserStorageData({
        key: TOKEN_STORAGE_KEY
      })

      window.location.reload()
    }

    return Promise.reject(error)
  }
)
