import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Show = styled.p`
  color: #666b7b;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  line-height: 24px;
`
