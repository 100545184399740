// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55"
    height="54"
    viewBox="0 0 55 54"
    fill="none"
  >
    <g clip-path="url(#clip0_36_7592)">
      <rect x="0.5" width="54" height="54" rx="27" fill="#FDF4F5" />
      <rect x="27.5" width="27" height="54" fill="#F6BEC7" fill-opacity="0.4" />
      <path
        d="M28.086 15.586L30.5 18L32.5 33C32.5 33.5304 32.2893 34.0391 31.9142 34.4142C31.5391 34.7893 31.0304 35 30.5 35H18.5C17.9696 35 17.4609 34.7893 17.0858 34.4142C16.7107 34.0391 16.5 33.5304 16.5 33V17C16.5 16.4696 16.7107 15.9609 17.0858 15.5858C17.4609 15.2107 17.9696 15 18.5 15H26.672C27.2024 15.0001 27.711 15.2109 28.086 15.586Z"
        fill="#F6BEC7"
      />
      <path
        d="M32.5 20L30.086 17.586C29.711 17.2109 29.2024 17.0001 28.672 17H20.5C19.9696 17 19.4609 17.2107 19.0858 17.5858C18.7107 17.9609 18.5 18.4696 18.5 19V35C18.5 35.5304 18.7107 36.0391 19.0858 36.4142C19.4609 36.7893 19.9696 37 20.5 37H32.5C33.0304 37 33.5391 36.7893 33.9142 36.4142C34.2893 36.0391 34.5 35.5304 34.5 35M22.5 33H23.5M35.878 27.626C36.2764 27.2276 36.5001 26.6874 36.5001 26.124C36.5001 25.5606 36.2764 25.0204 35.878 24.622C35.4796 24.2236 34.9394 23.9999 34.376 23.9999C33.8126 23.9999 33.2724 24.2236 32.874 24.622L28.864 28.634C28.6262 28.8716 28.4522 29.1653 28.358 29.488L27.521 32.358C27.4959 32.444 27.4944 32.5353 27.5166 32.6221C27.5389 32.7089 27.5841 32.7882 27.6474 32.8516C27.7108 32.9149 27.7901 32.9601 27.8769 32.9824C27.9637 33.0046 28.055 33.0031 28.141 32.978L31.011 32.141C31.3337 32.0468 31.6274 31.8728 31.865 31.635L35.878 27.626Z"
        stroke="#5F061D"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_36_7592">
        <rect x="0.5" width="54" height="54" rx="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
