// Dependencies
import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`

export const Label = styled.p`
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
`

type SelectorItemContainerStyleProps = {
  isSelected: boolean
}

export const SelectorItemContainer = styled.div<SelectorItemContainerStyleProps>`
  display: flex;
  align-items: center;
  gap: 12px;

  height: 38px;
  padding: 8px 20px 8px 24px;
  justify-content: center;
  gap: 12px;

  border-radius: 100px;
  border: 1px solid;
  cursor: pointer;

  :hover {
    border-color: #25272d;
  }

  ${({ isSelected }) =>
    !!isSelected &&
    css`
      border-color: #28b583;
      background: #d1f3e7;

      ${Label} {
        color: #157050;
      }
    `}

  ${({ isSelected }) =>
    !isSelected &&
    css`
      border-color: #ced0dd;
      background: #fafafc;

      ${Label} {
        color: #666b7b;
      }
    `}
`
