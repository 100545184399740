// Dependencies
import styled from 'styled-components'
import { pageGutter } from 'styles/gutter'
import { MediaQueries } from 'utils/breakpoints'

export const Container = styled.div`
  ${MediaQueries.medium} {
    ${pageGutter}

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    border: 1px solid #ced0dd;
    background: #fff;
    padding: 0 !important;
  }
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${MediaQueries.medium} {
    border-radius: 0 0 16px 16px;
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  background: #f0f1f7;

  ${MediaQueries.medium} {
    border-radius: 16px 16px 0 0;
  }

  ${MediaQueries.amedium} {
    display: grid;
    grid-template-columns: 376px auto;
  }
`

export const WarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

export const WarningTitle = styled.p`
  color: #000;
  font-family: 'Source Sans 3';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`

export const WarningDescription = styled.p`
  color: #25272d;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const ExamOptionsSelectorsCompositionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  /* overflow: hidden; */
  align-self: stretch;

  ${MediaQueries.medium} {
    flex-direction: row;
  }
`

export const ExamActionsCompositionWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background: #fafafc;
`

export const ExamQuestionsCompositionWrapper = styled.div`
  width: 100%;
  padding: 16px;
  background: #fff;

  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  display: grid;
  grid-template-columns: 1fr;

  ${MediaQueries.medium} {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 8px;
  }

  ${MediaQueries.amedium} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const ExamYearSelectorCompositionWrapper = styled.div`
  width: 100%;
  padding: 16px;
`
