// Dependencies
import React, { FunctionComponent } from 'react';

// Types
import { CheckboxProps } from './checkbox.types';

// Styles
import {
  Container,
  CheckboxItem,
  CheckboxWrapper,
  ErrorMessage,
} from './checkbox.styles';
import { ConditionallyRender } from 'components/utilities/conditionally-render';


export const Checkbox: FunctionComponent<CheckboxProps> = ({
  errorMessage,
  register,
  textElements,
  ...checkboxDefaultProps
}) => {
  return (
    <Container>
      <CheckboxWrapper>
        <CheckboxItem type="checkbox" {...register} {...checkboxDefaultProps} />

        {textElements}
      </CheckboxWrapper>

      <ConditionallyRender
        shouldRender={!!errorMessage}
        content={<ErrorMessage>{errorMessage}</ErrorMessage>}
      />
    </Container>
  );
};
