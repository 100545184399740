// Dependencies
import styled, { css } from 'styled-components'

// Types
import { TooltipArrowGuidances } from './tooltip.types'

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`

type TooltipContentWrapperStyleProps = {
  hasArrow: boolean
  arrowGuidance: TooltipArrowGuidances
  width?: number
  positionAdjustment?: number
}

export const TooltipContentWrapper = styled.div<TooltipContentWrapperStyleProps>`
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  border-radius: 8px;
  background: #25272d;

  position: absolute;

  z-index: 1;

  ${({ width }) =>
    !!width &&
    css`
      width: ${width}px;
    `}

  ${({ width }) =>
    !width &&
    css`
      width: 100%;
    `}

  ${({ positionAdjustment }) =>
    !!positionAdjustment &&
    css`
      left: -${positionAdjustment}px;
    `}

  ${({ hasArrow }) =>
    hasArrow &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px;
      }
    `}

  ${({ hasArrow, arrowGuidance }) =>
    arrowGuidance === 'up' &&
    css`
      &::before {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-color: transparent transparent #25272d transparent;
      }

      top: 100%;
      transform: translateY(${hasArrow ? '16px' : '8px'});
    `}

  ${({ hasArrow, arrowGuidance }) =>
    arrowGuidance === 'right' &&
    css`
      &::before {
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        border-color: transparent #25272d transparent transparent;
      }

      top: 50%;
      left: 100%;
      transform: translate(${hasArrow ? '16px' : '8px'}, -50%);
    `}

  ${({ hasArrow, arrowGuidance }) =>
    arrowGuidance === 'down' &&
    css`
      &::before {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-color: #25272d transparent transparent transparent;
      }

      bottom: 100%;
      transform: translateY(-${hasArrow ? '16px' : '8px'});
    `}

  ${({ hasArrow, arrowGuidance }) =>
    arrowGuidance === 'left' &&
    css`
      &::before {
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        border-color: transparent transparent transparent #25272d;
      }

      top: 50%;
      right: 100%;
      transform: translate(-${hasArrow ? '16px' : '8px'}, -50%);
    `}
`

export const TooltipLabel = styled.p`
  color: #fafafc;
  font-family: 'Source Sans 3';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`
