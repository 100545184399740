// Dependencies
import React, { FunctionComponent, SVGProps } from 'react'

export const ReactComponent: FunctionComponent<
  SVGProps<SVGSVGElement>
> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
  >
    <rect width="54" height="54" rx="27" fill="#FDF4F5" />
    <rect x="27" width="27" height="54" fill="#F6BEC7" fillOpacity="0.4" />
    <circle cx="28" cy="21" r="6" fill="#F6BEC7" />
    <path
      d="M20.4158 36.9958C19.8239 37.1753 19.1901 36.8409 19.095 36.2296C18.862 34.7316 19.0584 33.1898 19.6734 31.7873C20.4391 30.0411 21.8034 28.6257 23.5204 27.7964C25.2373 26.967 27.194 26.7782 29.0379 27.2639C30.5188 27.654 31.8486 28.4586 32.8771 29.5723C33.2968 30.0268 33.1648 30.7311 32.6563 31.0832C32.1477 31.4354 31.4564 31.2985 31.0126 30.8676C30.3064 30.1818 29.4313 29.684 28.4673 29.43C27.1397 29.0803 25.7309 29.2163 24.4947 29.8134C23.2585 30.4105 22.2762 31.4296 21.7249 32.6869C21.3245 33.5998 21.1705 34.5948 21.2687 35.5743C21.3304 36.1898 21.0078 36.8164 20.4158 36.9958Z"
      fill="#F6BEC7"
    />
    <path
      d="M19 35C18.9999 33.4603 19.4441 31.9533 20.2793 30.6599C21.1146 29.3664 22.3053 28.3415 23.7086 27.708C25.1119 27.0746 26.6682 26.8595 28.1908 27.0887C29.7133 27.3178 31.1373 27.9815 32.292 29M32 22C32 24.7614 29.7614 27 27 27C24.2386 27 22 24.7614 22 22C22 19.2386 24.2386 17 27 17C29.7614 17 32 19.2386 32 22Z"
      stroke="#5F061D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33 33L35 35L39 31"
      stroke="#DF1D3D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
