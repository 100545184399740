// Dependencies
import * as zod from 'zod'

const signInValidationSchema = zod.object({
  email: zod.string().trim().min(1, 'O e-mail é obrigatório'),
  password: zod.string().trim().min(1, 'A senha é obrigatória')
})

export const signInFormValidationSchema = zod.object({
  signIn: signInValidationSchema
})

export type SignInFormData = zod.infer<typeof signInFormValidationSchema>
