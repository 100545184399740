import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState
} from 'react'

// Utils
import { useWindowDimensions } from 'hooks/windows-dimensions'

// Styles
import {
  CloseLabel,
  CloseWrapper,
  Container,
  Content
} from './picture-in-picture.styles'

// Components
import { ConditionallyRender } from 'components/utilities/conditionally-render'
import { Icon } from 'component-library/icon'

export const PictureInPicture: FunctionComponent<any> = ({
  iframeElement,
  resultsComposition
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const { width: windowWidth } = useWindowDimensions()

  const [isPictureInPicture, setIsPictureInPicture] = useState(false)
  const [isPipClosed, setIsPipClosed] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const shouldActivatePip =
        window.scrollY > 700 &&
        (windowWidth < 768 ||
          (windowWidth >= 768 && windowWidth < 1024) ||
          windowWidth >= 1024)

      if (!isPipClosed && shouldActivatePip) {
        setIsPictureInPicture(true)
      } else if (!shouldActivatePip) {
        setIsPictureInPicture(false)
        setIsPipClosed(false)
      }
    }

    document.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [windowWidth, isPipClosed])

  const handleCloseClick = () => {
    setIsPictureInPicture(false)
    setIsPipClosed(true)
  }

  return (
    <Container>
      <Content isActive={isPictureInPicture} ref={iframeRef}>
        <ConditionallyRender
          minimumBreakpoint="tablet"
          shouldRender={isPictureInPicture}
          content={
            <CloseWrapper onClick={handleCloseClick}>
              <CloseLabel>Fechar</CloseLabel>
              <Icon name="close" color="#25272D" size={16} />
            </CloseWrapper>
          }
        />

        {iframeElement}

        <ConditionallyRender
          maximumBreakpoint="tablet"
          shouldRender={isPictureInPicture}
          content={resultsComposition}
        />
      </Content>
    </Container>
  )
}
