// Dependencies
import React, { FunctionComponent, useRef, useState } from 'react'

// Types
import { InputProps } from './input.types'

// Components

// Styles
import {
  Container,
  InputWrapper,
  Label,
  InputField,
  UnderInputMessage,
  PasswordIcon
} from './input.styles'
import { ConditionallyRender } from 'components/utilities/conditionally-render'

// Assets
import eye from 'assets/svg/home/eye.svg'

export const Input: FunctionComponent<InputProps> = ({
  register,
  label,
  errorMessage,
  hasInputTypeToggle,
  isEmail,
  additionalElement,
  ...inputDefaultProps
}) => {
  const inputParentRef = useRef(null)

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  return (
    <Container className="input" hasError={!!errorMessage}>
      <Label>{label}</Label>

      <InputWrapper ref={inputParentRef}>
        <InputField
          isEmailInput={isEmail}
          {...register}
          {...inputDefaultProps}
          {...(hasInputTypeToggle && {
            type: isPasswordVisible ? 'text' : 'password'
          })}
        />

        <ConditionallyRender
          shouldRender={!!hasInputTypeToggle}
          content={
            <PasswordIcon
              src={eye}
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            />
          }
        />
      </InputWrapper>

      <ConditionallyRender
        shouldRender={!!errorMessage}
        content={<UnderInputMessage>{errorMessage}</UnderInputMessage>}
      />

      <ConditionallyRender
        shouldRender={!!additionalElement}
        content={additionalElement}
      />
    </Container>
  )
}
