// Dependencies
import React, { FunctionComponent } from 'react';

// Styles
import { Container } from './skeleton.styles';

// Types
import { SkeletonProps } from './skeleton.types';

export const Skeleton: FunctionComponent<SkeletonProps> = ({
  height,
  width,
  borderRadius,
  animationColor,
}) => {
  return (
    <Container
      height={height}
      width={width}
      borderRadius={borderRadius}
      animationColor={animationColor}
    />
  );
};
