// Dependencies
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`

export const ErrorMessage = styled.p`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #e40000;
`

export const InputElementsWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`
