import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

export const Label = styled.p`
  color: #000;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

export const Wrapper = styled.div<{ open: boolean }>`
  position: relative;
  width: 100%;

  > div.select-header {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #ced0dd;
    background: #fff;
    transition: all 0.3s;

    cursor: pointer;
  }

  > span.selected-label {
    color: #000;
    font-family: 'Source Sans 3';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  > div.select-body {
    width: 100%;

    display: flex;
    flex-direction: column;

    padding: 12px 16px;
    border-radius: 0 0 8px 8px;
    border: 1px solid #ced0dd;
    border-top: none;
    background: #fff;
    pointer-events: none;
    transition: all 0.15s;
    position: absolute;
    top: 62%;
    z-index: 2;
    opacity: 0;
    overflow: hidden;

    ${({ open }) =>
      !!open &&
      css`
        pointer-events: all;
        transition: all 0.3s;
        opacity: 1;
      `}

    > div.select-option {
      width: 100%;

      padding: 12px 8px;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      transition: all 0.15s;

      span {
        color: #000;
        font-family: 'Source Sans 3';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    > div.select-option:hover {
      background: rgb(193, 22, 54);

      span {
        color: #fff;
      }
    }
  }
`
