// Dependencies
import React, { Fragment, FunctionComponent } from 'react'

// Types
import { WelcomeUserCardProps } from './welcome-user-card.types'

// Styles
import {
  Container,
  Label,
  SEOLabel,
  SubLabel,
  UnofficialAndActionWrapper
} from './welcome-user-card.styles'

// Components
import { ConditionallyRender } from 'components/utilities/conditionally-render'

export const WelcomeUserCard: FunctionComponent<WelcomeUserCardProps> = ({
  usernameLabel,
  separatorLabel,
  unofficialLabel,
  shouldDisplayOnlyUnofficialLabel,
  actionElement
}) => {
  return (
    <Container>
      <ConditionallyRender
        maximumBreakpoint="tablet"
        content={<SubLabel>{unofficialLabel}</SubLabel>}
      />

      <ConditionallyRender
        minimumBreakpoint="tablet"
        content={
          <Fragment>
            <ConditionallyRender
              shouldRender={!!shouldDisplayOnlyUnofficialLabel}
              content={<SEOLabel>{unofficialLabel}</SEOLabel>}
            />

            <ConditionallyRender
              shouldRender={!shouldDisplayOnlyUnofficialLabel}
              content={
                <Fragment>
                  <Label>{usernameLabel}</Label>

                  <UnofficialAndActionWrapper>
                    <SEOLabel>{unofficialLabel}</SEOLabel>
                    <SubLabel>{separatorLabel}</SubLabel>
                    {actionElement}
                  </UnofficialAndActionWrapper>
                </Fragment>
              }
            />
          </Fragment>
        }
      />
    </Container>
  )
}
