import { Icon } from 'component-library/icon'
import { Tooltip } from 'component-library/tooltip'
import { ConditionallyRender } from 'components/utilities/conditionally-render'
import React, { Fragment, FunctionComponent } from 'react'
import {
  CancelledQuestionLabel,
  CancelledQuestionWrapper,
  Container,
  IconWrapper,
  QuestionColumnContainer
} from './question.styles'

export const AlternativeOptions = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E'
} as const

export type AlternativeOption =
  (typeof AlternativeOptions)[keyof typeof AlternativeOptions]

export const Question: FunctionComponent<any> = ({
  questionNumber = '000',
  correctAlternative,
  selectedAlternative,
  isCanceled,
  hasCorrectionAvailable,
  hasVisibleCorrection,
  onQuestionClick
}) => {
  const rightQuest: boolean =
    !isCanceled &&
    !!selectedAlternative &&
    !!correctAlternative &&
    selectedAlternative === correctAlternative
  //------------------------------

  const wrongQuestion: boolean =
    !isCanceled &&
    !!selectedAlternative &&
    !!correctAlternative &&
    selectedAlternative !== correctAlternative
  //------------------------------

  const awaitingCorrection: boolean =
    !isCanceled &&
    hasCorrectionAvailable !== undefined &&
    !hasCorrectionAvailable
  //------------------------------

  const correctionAvailable: boolean =
    !isCanceled &&
    hasCorrectionAvailable !== undefined &&
    hasCorrectionAvailable &&
    !hasVisibleCorrection
  //------------------------------

  const previewCorrection: boolean =
    !isCanceled &&
    hasCorrectionAvailable !== undefined &&
    hasCorrectionAvailable &&
    !selectedAlternative &&
    hasVisibleCorrection
  //------------------------------

  const questionWithoutCorrectAnswer = !isCanceled && !correctAlternative

  const alternativeConfigs: any[] = [
    {
      isSelectedAlternative: selectedAlternative === AlternativeOptions.A,
      alternativeLabel: AlternativeOptions.A,
      isRightAlternative: correctAlternative === AlternativeOptions.A
    },
    {
      isSelectedAlternative: selectedAlternative === AlternativeOptions.B,
      alternativeLabel: AlternativeOptions.B,
      isRightAlternative: correctAlternative === AlternativeOptions.B
    },
    {
      isSelectedAlternative: selectedAlternative === AlternativeOptions.C,
      alternativeLabel: AlternativeOptions.C,
      isRightAlternative: correctAlternative === AlternativeOptions.C
    },
    {
      isSelectedAlternative: selectedAlternative === AlternativeOptions.D,
      alternativeLabel: AlternativeOptions.D,
      isRightAlternative: correctAlternative === AlternativeOptions.D
    },
    {
      isSelectedAlternative: selectedAlternative === AlternativeOptions.E,
      alternativeLabel: AlternativeOptions.E,
      isRightAlternative: correctAlternative === AlternativeOptions.E
    }
  ]

  const contentClasses = [
    'content',
    rightQuest && 'correct-content',
    wrongQuestion && 'wrong-content',
    awaitingCorrection && 'awaiting-correction',
    correctionAvailable && 'correction-available',
    previewCorrection && 'preview-correction'
  ]
    .filter(Boolean)
    .join(' ')

  const questionNumberClasses = [
    'question-number',
    rightQuest && 'correct-question-number',
    wrongQuestion && 'wrong-question-number',
    awaitingCorrection && 'question-number-awaiting-correction'
  ]
    .filter(Boolean)
    .join(' ')

  const alternativeClasses = (
    isSelectedAlternative: boolean,
    isRightAlternative: boolean
  ) =>
    [
      'alternative',
      rightQuest && isSelectedAlternative && 'correct-question-alternative',
      wrongQuestion && isSelectedAlternative && 'wrong-question-alternative',
      questionWithoutCorrectAnswer &&
        isSelectedAlternative &&
        'question-without-correct-answer',
      rightQuest && isSelectedAlternative && 'correct-alternative',
      wrongQuestion && isSelectedAlternative && 'wrong-alternative',
      isRightAlternative &&
        wrongQuestion &&
        'is-right-alternative-of-wrong-question',
      !isSelectedAlternative && 'hover',
      previewCorrection &&
        isRightAlternative &&
        'is-right-alternative-of-preview-question'
    ]
      .filter(Boolean)
      .join(' ')

  return (
    <Container isCanceled={!!isCanceled}>
      <div className={contentClasses}>
        <ConditionallyRender
          shouldRender={awaitingCorrection || correctionAvailable}
          content={
            <Tooltip
              tooltipContent={
                awaitingCorrection ? 'Aguardando correção' : 'Correção liberada'
              }
              arrowGuidance="down"
              openedContentWidth={170}
              openedContentPositionAdjustment={74}
              contentElement={
                <IconWrapper
                  backgroundColor={awaitingCorrection ? '#FDDEA4' : '#DDD2DF'}
                >
                  <Icon
                    name={awaitingCorrection ? 'clock' : 'check'}
                    size={12}
                    color={awaitingCorrection ? '#965307' : '#794A7D'}
                  />
                </IconWrapper>
              }
            />
          }
        />

        <div className={questionNumberClasses}>{questionNumber}</div>

        <ConditionallyRender
          shouldRender={!isCanceled}
          content={alternativeConfigs.map((alternative) => (
            <div
              key={alternative.alternativeLabel}
              className={alternativeClasses(
                alternative.isSelectedAlternative,
                alternative.isRightAlternative
              )}
              onClick={() => {
                const event = new CustomEvent('questionClick', {
                  detail: {
                    selectedAlternative: alternative.alternativeLabel,
                    questionNumber: questionNumber
                  }
                })

                onQuestionClick(event)
              }}
            >
              {alternative.alternativeLabel}
            </div>
          ))}
        />

        <ConditionallyRender
          shouldRender={!!isCanceled}
          content={
            <CancelledQuestionWrapper>
              <CancelledQuestionLabel>Questão anulada</CancelledQuestionLabel>
            </CancelledQuestionWrapper>
          }
        />
      </div>
    </Container>
  )
}

export const QuestionColumn: FunctionComponent<any> = ({ content }) => {
  return <QuestionColumnContainer>{content}</QuestionColumnContainer>
}
