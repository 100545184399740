// Dependencies
import React, { FunctionComponent } from 'react'

// Types
import {
  AuthCardSelectorProps,
  AuthCardSelectorItemProps
} from './auth-card-selector.types'

// Styles
import {
  ActiveIconWrapper,
  AuthCardSelectorContainer,
  AuthCardSelectorContent,
  AuthCardSelectorContentWrapper,
  AuthCardSelectorItemContainer,
  AuthCardSelectorItemContentWrapper,
  AuthCardSelectorItemDescription,
  AuthCardSelectorItemTitle,
  AuthCardSelectorTitleIcon,
  AuthCardSelectorTitleWrapper
} from './auth-card-selector.styles'

import { ConditionallyRender } from 'components/utilities/conditionally-render'
import { ScreenWidthRender } from 'components/utilities/screen-width-render'
import { Icon } from 'component-library/icon'

// Components
export const AuthCardSelector: FunctionComponent<AuthCardSelectorProps> = ({
  selectorItemsCompositions
}) => (
  <AuthCardSelectorContainer>
    <AuthCardSelectorContent>
      {selectorItemsCompositions}
    </AuthCardSelectorContent>
  </AuthCardSelectorContainer>
)

export const AuthCardSelectorItem: FunctionComponent<
  AuthCardSelectorItemProps
> = ({ isActive, title, description, headerIcon, handleClick }) => {
  return (
    <AuthCardSelectorItemContainer
      isActive={!!isActive}
      hasAction={!!handleClick}
      onClick={handleClick}
    >
      <AuthCardSelectorItemContentWrapper>
        <AuthCardSelectorContentWrapper>
          <AuthCardSelectorTitleWrapper>
            <AuthCardSelectorTitleIcon src={headerIcon} />

            <AuthCardSelectorItemTitle>{title}</AuthCardSelectorItemTitle>
          </AuthCardSelectorTitleWrapper>

          <ConditionallyRender
            shouldRender={!!isActive}
            content={
              <ActiveIconWrapper>
                <Icon name="check" size={16} color="#FFFFFF" />
              </ActiveIconWrapper>
            }
          />
        </AuthCardSelectorContentWrapper>

        <AuthCardSelectorItemDescription>
          {description}
        </AuthCardSelectorItemDescription>
      </AuthCardSelectorItemContentWrapper>
    </AuthCardSelectorItemContainer>
  )
}
